import {
  EncryptRequest,
  EncryptResponse,
  EventListRequest,
  EventListResponse,
  LocationListRequest,
  LocationListResponse,
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  LogoutResponse,
  MonthlyAllocatedDaysRequest,
  MonthlyAllocatedDaysResponse,
  MyEventListRequest,
  MyEventListResponse,
  MyHistoryRequest,
  MyHistoryResponse,
  MyNonEventListRequest,
  MyNonEventListResponse,
  MyScheduleRequest,
  MyScheduleResponse,
  NonEventListRequest,
  NonEventListResponse,
  NonProgramListRequest,
  NonProgramListResponse,
  PersonelAddRequest,
  PersonelAddResponse,
  PersonelHistoryRequest,
  PersonelHistoryResponse,
  PersonelListRequest,
  PersonelListResponse,
  PersonelNonProgramAddRequest,
  PersonelNonProgramAddResponse,
  PersonelNonProgramListRequest,
  PersonelNonProgramListResponse,
  PersonelNonProgramRemoveRequest,
  PersonelNonProgramRemoveResponse,
  PersonelRemoveRequest,
  PersonelRemoveResponse,
  PersonelScheduleRequest,
  PersonelScheduleResponse,
  PersonelSearchRequest,
  PersonelSearchResponse,
  PositionListRequest,
  PositionListResponse,
  ReportingRequest,
  ReportingResponse,
  SaveNonProgramWorklogRequest,
  SaveNonProgramWorklogResponse,
  SaveProgramBackdateNoteRequest,
  SaveProgramBackdateNoteResponse,
  SaveProgramWorklogRequest,
  SaveProgramWorklogResponse,
} from '../types';
import { request } from './api.service';

export const getListEvents = async (data: EventListRequest): Promise<EventListResponse> =>
  await request.post('scheduler/schedule', data);

export const getMyListEvents = async (data: MyEventListRequest): Promise<MyEventListResponse> =>
  await request.post('scheduler/schedule', data);

export const getMonthlyAllocatedDays = async (
  data: MonthlyAllocatedDaysRequest,
): Promise<MonthlyAllocatedDaysResponse> => await request.post('scheduler/schedule', data);

export const getListPersonel = async (data: PersonelListRequest): Promise<PersonelListResponse> =>
  await request.post('scheduler/schedule', data);

export const getListPersonelNonProgram = async (
  data: PersonelNonProgramListRequest,
): Promise<PersonelNonProgramListResponse> => await request.post('scheduler/schedule', data);

export const getSearchPersonel = async (data: PersonelSearchRequest): Promise<PersonelSearchResponse> =>
  await request.post('scheduler/schedule', data);

export const getListLocation = async (data: LocationListRequest): Promise<LocationListResponse> =>
  await request.post('scheduler/schedule', data);

export const getListNonProgram = async (data: NonProgramListRequest): Promise<NonProgramListResponse> =>
  await request.post('scheduler/schedule', data);

export const deletePersonel = async (data: PersonelRemoveRequest): Promise<PersonelRemoveResponse> =>
  await request.post('scheduler/schedule', data);

export const deletePersonelNonProgram = async (
  data: PersonelNonProgramRemoveRequest,
): Promise<PersonelNonProgramRemoveResponse> => await request.post('scheduler/schedule', data);

export const insertPersonel = async (data: PersonelAddRequest): Promise<PersonelAddResponse> =>
  await request.post('scheduler/schedule', data);

export const saveProgramWorklog = async (data: SaveProgramWorklogRequest): Promise<SaveProgramWorklogResponse> =>
  await request.post('scheduler/schedule', data);

export const saveNonProgramWorklog = async (
  data: SaveNonProgramWorklogRequest,
): Promise<SaveNonProgramWorklogResponse> => await request.post('scheduler/schedule', data);

export const saveProgramBackdateNote = async (
  data: SaveProgramBackdateNoteRequest,
): Promise<SaveProgramBackdateNoteResponse> => await request.post('scheduler/schedule', data);

export const insertPersonelNonProgram = async (
  data: PersonelNonProgramAddRequest,
): Promise<PersonelNonProgramAddResponse> => await request.post('scheduler/schedule', data);

export const getListNonEvents = async (data: NonEventListRequest): Promise<NonEventListResponse> =>
  await request.post('scheduler/schedule', data);

export const getMyListNonEvents = async (data: MyNonEventListRequest): Promise<MyNonEventListResponse> =>
  await request.post('scheduler/schedule', data);

export const getDataReporting = async (data: ReportingRequest): Promise<ReportingResponse> =>
  await request.post('scheduler/schedule', data);

export const getDataPosition = async (data: PositionListRequest): Promise<PositionListResponse> =>
  await request.post('scheduler/schedule', data);

export const doLogin = async (data: LoginRequest): Promise<LoginResponse> => {
  return await request.post('scheduler/login', data);
};

export const doLogout = async (data: LogoutRequest): Promise<LogoutResponse> =>
  await request.post('scheduler/logout', data);

export const doEncrypt = async (data: EncryptRequest): Promise<EncryptResponse> =>
  await request.post('scheduler/utility', data);

export const getMySchedule = async (data: MyScheduleRequest): Promise<MyScheduleResponse> =>
  await request.post('scheduler/schedule', data);

export const getMyHistory = async (data: MyHistoryRequest): Promise<MyHistoryResponse> =>
  await request.post('scheduler/schedule', data);

export const getPersonelHistory = async (data: PersonelHistoryRequest): Promise<PersonelHistoryResponse> =>
  await request.post('scheduler/schedule', data);

export const getPersonelSchedule = async (data: PersonelScheduleRequest): Promise<PersonelScheduleResponse> =>
  await request.post('scheduler/schedule', data);

import type { FC } from 'react';

const DashboardPage: FC = () => {
  return (
    <div className="mx-auto max-w-screen-xl p-4 dark:text-white lg:p-12">
      <h2 className="mb-4 text-2xl font-bold text-gray-900 dark:text-white lg:text-center lg:text-4xl lg:font-extrabold lg:leading-snug 2xl:px-48">
        VIVAT Scheduler
      </h2>
      <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:mb-16 lg:text-center lg:text-xl xl:px-64">
        Personnels program and non program scheduling
      </p>
    </div>
  );
};

export default DashboardPage;

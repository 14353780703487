import React, { FC, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import swal from 'sweetalert';
import { Button, Label, Table } from '../../lib';
import { getSearchPersonel } from '../../services/scheduler.service';
import { PersonelSearch, PersonelSearchRequest, PersonelSearchResponse } from '../../types';
import { ReportPage } from '../../types/enum/report';
import { formatDate } from '../../utils/date.util';
import { log } from '../../utils/log.util';

const PersonnelHourUsagePage: FC = () => {
  const [page, setPage] = useState<ReportPage>(ReportPage.FORM_HOUR_USAGE);
  const [iDateStart, setIDateStart] = useState<Date>(new Date());
  const [iDateEnd, setIDateEnd] = useState<Date>(new Date());
  const [submitted, setSubmitted] = useState(false);
  const [personelsSearch, setPersonelsSearch] = useState<PersonelSearch[]>([]);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState('1');
  const [thresholdValue, setThresholdValue] = useState('160');
  const thresholdRef = useRef(null);
  const conditionRef = useRef(null);

  const getConditionLabel = () => {
    if (selectedOption === '1') {
      return 'Under Hours';
    } else if (selectedOption === '2') {
      return 'Equal & Over Hours';
    }
  };

  const searchPersonel = async (search: string) => {
    try {
      const params: PersonelSearchRequest = {
        api_version: '1.0',
        action_type: 'personnel-search',
        token: cookies.access_token,
        parameters: {
          name: search,
          position: '',
        },
      };
      log(params, 'listPersonel-req');
      setLoading(true);
      const list: PersonelSearchResponse = await getSearchPersonel(params);
      setLoading(false);
      log(list, 'listPersonel-res');

      if (list && list.data && list.data.personnel && list.data.personnel.length !== 0) {
        const sortedPersonnels = [...list.data.personnel].sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setPersonelsSearch(sortedPersonnels);
      } else {
        setPersonelsSearch([]);
      }
    } catch (error) {
      console.error('Error occurred while searching personnel:', error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const thresholdValue = thresholdRef.current.value;
    const conditionValue = conditionRef.current.value;
    setSelectedOption(conditionValue);
    setThresholdValue(thresholdValue);
    setSubmitted(true);
    setPage(ReportPage.REPORT_HOUR_USAGE);
    searchPersonel('');
  };

  const FormHourUsage: FC = () => {
    return (
      <div className="mx-5 my-3">
        <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
          Hour Usage
        </h2>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="flex gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="startDate" value="Start Date" />
              </div>
              <DatePicker selected={iDateStart} onChange={(date) => setIDateStart(date)} dateFormat="MMMM d, yyyy" />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="endDate" value="End Date" />
              </div>
              <DatePicker selected={iDateEnd} onChange={(date) => setIDateEnd(date)} dateFormat="MMMM d, yyyy" />
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="threshold" value="Threshold" />
              </div>
              <input type="text" id="threshold" style={{ borderRadius: '5px' }} ref={thresholdRef} defaultValue="160" />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="condition" value="Condition" />
              </div>
              <select id="condition" style={{ borderRadius: '5px' }} ref={conditionRef}>
                <option value="1">Under Hours</option>
                <option value="2">Equal & Over Hours</option>
              </select>
            </div>
          </div>
          <Button className="mb-2 mt-1" type="submit" style={{ width: '100px' }}>
            Submit
          </Button>
        </form>
      </div>
    );
  };

  const adjustDate = (date) => {
    const isoString = date.toISOString();
    return isoString.substring(0, 10);
  };

  const getEmpHourSpent = async (empID, date) => {
    console.log('looking for hour spent for ID ' + empID);
    let result;
    const dateFilter = adjustDate(date);
    try {
      const response = await fetch('http://stg-be-roster.an.tv/scheduler/schedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_version: '1.0',
          action_type: 'my-team-member-underhours',
          token: cookies.access_token,
          parameters: {
            emp_id: parseInt(empID), // Convert empID to a number
            datetime_start: dateFilter,
            datetime_end: dateFilter,
          },
        }),
      });
      const data = await response.json();
      if (data.success && data.data !== null) {
        return data.data.hours_spent;
      }
    } catch (error) {
      //console.error('Error:', error);
    }
    return result;
  };

  const getReportData = () => {
    console.log('Report is loaded');
    console.log('Retrieve report data');
    const dateRange = [];
    const currentDate = new Date(iDateStart);

    while (currentDate <= iDateEnd) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const totalPersonel = personelsSearch.length;
    const totalDate = dateRange.length;
    const totalReport = totalPersonel * totalDate;
    let reportCounter = 0;
    const HourSpentData = [];
    const total_hours = {}; // Object to store total hours for each personnel.id

    personelsSearch.forEach((personel) => {
      if (page !== ReportPage.REPORT_HOUR_USAGE) {
        return;
      }

      const personnelId = personel.id;
      total_hours[personnelId] = 0; // Initialize total hours for the current personnel.id

      dateRange.forEach((date) => {
        if (page !== ReportPage.REPORT_HOUR_USAGE) {
          return;
        }

        getEmpHourSpent(personnelId, date)
          .then((result) => {
            const tableCell = document.getElementById(personnelId + '-' + adjustDate(date));

            if (result) {
              if (tableCell) {
                tableCell.innerHTML = result;
              }
              total_hours[personnelId] += result; // Accumulate the result for the current personnel.id
            } else {
              if (tableCell) {
                tableCell.innerHTML = '0';
              }
            }

            const totalCell = document.getElementById('hours-' + personnelId + '-total');
            if (totalCell) {
              totalCell.innerHTML = '' + total_hours[personnelId];
            }

            log(result, 'hour usage of ' + personnelId + ' on ' + date);
            HourSpentData.push(result);
            reportCounter = reportCounter + 1;
            const reportPercent = Math.round((reportCounter / totalReport) * 100);
            const reportProgress = document.getElementById('report_progress');
            reportProgress.innerHTML = ' ... ' + reportPercent + '%';

            if (reportPercent >= 100) {
              reportProgress.innerHTML = '';

              // Remove rows with TOTAL = 0
              // This solution is not ideal guys he he he, it doesn't meet the reactjs standard :P
              const table = document.getElementById('spent-hour-report');
              const rows = table.getElementsByTagName('tr');
              for (let i = rows.length - 1; i >= 0; i--) {
                const valueCell = rows[i].getElementsByTagName('td')[1];
                if (selectedOption === '1') {
                  //Show only under hours
                  if (parseFloat(valueCell.textContent) >= parseFloat(thresholdValue)) {
                    rows[i].remove();
                  }
                } else {
                  //Show only equal or overhours
                  if (parseFloat(valueCell.textContent) < parseFloat(thresholdValue)) {
                    rows[i].remove();
                  }
                }
              }
            }
            console.log('progress report is ' + reportPercent + '%');
          })
          .catch((error) => {
            // Handle any errors that occurred during the API request
            //console.error(error);
          });
      });
    });

    console.log(total_hours); // Log the total hours for each personnel.id
    return HourSpentData;
  };

  const ReportHourUsage: FC = () => {
    const dateRange = [];
    const currentDate = new Date(iDateStart);
    const endDate = new Date(iDateEnd);

    // Check if start and end dates are in the same month
    if (currentDate.getMonth() !== endDate.getMonth()) {
      swal('Warning', 'Please select date within one month range', 'warning');
      setPage(ReportPage.FORM);
      setSubmitted(false);
    } else {
      while (currentDate <= endDate) {
        dateRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    const formatDateCell = (date: Date) => {
      const formattedDate = formatDate(date, 'dd');
      return <span className="text-sm">{formattedDate}</span>;
    };

    const getMonthYear = () => {
      const currentDate = new Date(iDateStart);
      const month = currentDate.toLocaleString('default', { month: 'long' });
      const year = currentDate.getFullYear();
      return month + ' ' + year;
    };

    return (
      <div className="mx-5 my-3">
        <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
          <span
            className="m-auto inline-block cursor-pointer pr-2 align-middle"
            onClick={() => {
              setPage(ReportPage.FORM_HOUR_USAGE);
              setSubmitted(false);
            }}
          >
            <HiArrowNarrowLeft className="h-6 w-6" />
          </span>
          Hour Usage Report{' '}
          <span id="report_progress" className="text-sm" style={{ color: 'red' }}>
            &nbsp;
          </span>
        </h2>
        <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white" style={{ marginLeft: '35px' }}>
          {getConditionLabel()} , {getMonthYear()}
        </h3>
        <Table id="spent-hour-report" striped={true}>
          <Table.Head>
            <Table.HeadCell className="text-sm">Personnel</Table.HeadCell>
            <Table.HeadCell className="text-sm">Total</Table.HeadCell>
            {dateRange.map((date) => (
              <Table.HeadCell key={date.getTime()} className="text-sm">
                {formatDateCell(date)}
              </Table.HeadCell>
            ))}
          </Table.Head>
          <Table.Body className="divide-y">
            {personelsSearch.map((personel) => (
              <Table.Row key={personel.id}>
                <Table.Cell>{personel.name}</Table.Cell>
                <Table.Cell id={'hours-' + personel.id + '-' + 'total'}>?</Table.Cell>
                {dateRange.map((date) => (
                  <Table.Cell key={date.getTime()} id={personel.id + '-' + adjustDate(date)}>
                    ?
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <span id="report_loaded"></span>
      </div>
    );
  };

  let CurrentPage: FC = FormHourUsage;
  if (page === ReportPage.REPORT_HOUR_USAGE && submitted) {
    CurrentPage = ReportHourUsage;
  }

  const reportLoaded = document.getElementById('report_loaded');
  if (reportLoaded) {
    console.log('calling report data');
    getReportData();
  } else {
    console.log('Report is not loaded');
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-0 dark:text-white lg:px-4 lg:py-0">
      <div className="rounded-xl border border-gray-100 bg-white shadow transition hover:border-white hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:shadow-gray-800 dark:hover:border-gray-600">
        <div className="flex flex-col gap-8 dark:text-white">
          <CurrentPage />
        </div>
      </div>
    </div>
  );
};

export default PersonnelHourUsagePage;

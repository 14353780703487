import React, { FC, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import swal from 'sweetalert';
import { Button, Label, Table } from '../../lib';
import { getSearchPersonel } from '../../services/scheduler.service';
import { PersonelSearch, PersonelSearchRequest, PersonelSearchResponse } from '../../types';
import { ReportPage } from '../../types/enum/report';
import { formatDate } from '../../utils/date.util';
import { log } from '../../utils/log.util';

const ReportingPage: FC = () => {
  const [page, setPage] = useState<ReportPage>(ReportPage.FORM);
  const [iDateStart, setIDateStart] = useState<Date>(new Date());
  const [iDateEnd, setIDateEnd] = useState<Date>(new Date());
  const [submitted, setSubmitted] = useState(false);
  const [personelsSearch, setPersonelsSearch] = useState<PersonelSearch[]>([]);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const [loading, setLoading] = useState<boolean>(false);

  const searchPersonel = async (search: string) => {
    try {
      const params: PersonelSearchRequest = {
        api_version: '1.0',
        action_type: 'personnel-search',
        token: cookies.access_token,
        parameters: {
          name: search,
          position: '',
        },
      };
      log(params, 'listPersonel-req');
      setLoading(true);
      const list: PersonelSearchResponse = await getSearchPersonel(params);
      setLoading(false);
      log(list, 'listPersonel-res');

      if (list && list.data && list.data.personnel && list.data.personnel.length !== 0) {
        const sortedPersonnels = [...list.data.personnel].sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setPersonelsSearch(sortedPersonnels);
      } else {
        setPersonelsSearch([]);
      }
    } catch (error) {
      console.error('Error occurred while searching personnel:', error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
    setPage(ReportPage.REPORT);
    searchPersonel('');
  };

  const Form: FC = () => {
    return (
      <div className="mx-5 my-3">
        <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
          Team Activity
        </h2>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="flex gap-4">
            <div style={{ width: 'fit-content', minWidth: '150px', maxWidth: '300px' }}>
              <div className="mb-2 block">
                <Label htmlFor="startDate" value="Start Date" />
              </div>
              <DatePicker selected={iDateStart} onChange={(date) => setIDateStart(date)} dateFormat="MMMM d, yyyy" />
            </div>
            <div style={{ width: 'fit-content', minWidth: '150px', maxWidth: '300px' }}>
              <div className="mb-2 block">
                <Label htmlFor="endDate" value="End Date" />
              </div>
              <DatePicker selected={iDateEnd} onChange={(date) => setIDateEnd(date)} dateFormat="MMMM d, yyyy" />
            </div>
          </div>
          <Button className="mb-2 mt-1" type="submit" style={{ width: '100px' }}>
            Submit
          </Button>
        </form>
      </div>
    );
  };

  const adjustDate = (date) => {
    const isoString = date.toISOString();
    return isoString.substring(0, 10);
  };

  const getActivity = async (empID, date, token) => {
    let result;
    const dateFilter = adjustDate(date);
    try {
      const response = await fetch('http://stg-be-roster.an.tv/scheduler/schedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_version: '1.0',
          action_type: 'my-team-member-history',
          token: cookies.access_token,
          parameters: {
            emp_id: empID,
            datetime_start: dateFilter + ' 00:00',
            datetime_end: dateFilter + ' 23:59',
          },
        }),
      });
      const data = await response.json();
      if (data.success && data.data !== null && data.data.activities !== null) {
        const activities = data.data.activities;
        const activityNames = activities.map((activity) => activity.activity_name);
        const concatenatedNames = activityNames.join(', ');
        console.log(concatenatedNames);
        result = concatenatedNames;
      }
    } catch (error) {
      //console.error('Error:', error);
    }
    return result;
  };

  const Report: FC = () => {
    const dateRange = [];
    const currentDate = new Date(iDateStart);
    const endDate = new Date(iDateEnd);

    // Check if start and end dates are in the same month
    if (currentDate.getMonth() !== endDate.getMonth()) {
      swal('Warning', 'Please select date within one month range', 'warning');
      setPage(ReportPage.FORM);
      setSubmitted(false);
    } else {
      while (currentDate <= endDate) {
        dateRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    const formatDateCell = (date: Date) => {
      const formattedDate = formatDate(date, 'dd');
      return <span className="text-sm">{formattedDate}</span>;
    };

    const getMonthYear = () => {
      const currentDate = new Date(iDateStart);
      const month = currentDate.toLocaleString('default', { month: 'long' });
      const year = currentDate.getFullYear();
      return month + ' ' + year;
    };

    return (
      <div className="mx-5 my-3">
        <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
          <span
            className="m-auto inline-block cursor-pointer pr-2 align-middle"
            onClick={() => {
              setPage(ReportPage.FORM);
              setSubmitted(false);
            }}
          >
            <HiArrowNarrowLeft className="h-6 w-6" />
          </span>
          Team Activity Report{' '}
          <span id="report_progress" className="text-sm" style={{ color: 'red' }}>
            &nbsp;
          </span>
          <br />
        </h2>
        <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white" style={{ marginLeft: '35px' }}>
          {getMonthYear()}
        </h3>
        <Table striped={true}>
          <Table.Head>
            <Table.HeadCell className="text-sm">Personnel</Table.HeadCell>
            {dateRange.map((date) => (
              <Table.HeadCell key={date.getTime()} className="text-sm">
                {/*getDay(date)*/}
                {formatDateCell(date)}
              </Table.HeadCell>
            ))}
          </Table.Head>
          <Table.Body className="divide-y">
            {personelsSearch.map((personel) => (
              <Table.Row key={personel.id}>
                <Table.Cell>{personel.name}</Table.Cell>
                {dateRange.map((date) => (
                  <Table.Cell key={date.getTime()} id={personel.id + '-' + adjustDate(date)}>
                    ?
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <span id="report_loaded"></span>
      </div>
    );
  };

  const getReportData = () => {
    console.log('Report is loaded');
    console.log('Retrieve report data');
    const dateRange = [];
    const currentDate = new Date(iDateStart);
    while (currentDate <= iDateEnd) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const totalPersonel = personelsSearch.length;
    const totalDate = dateRange.length;
    const totalReport = totalPersonel * totalDate;
    let reportCounter = 0;

    personelsSearch.forEach((personel) => {
      if (page !== ReportPage.REPORT) {
        return;
      }
      dateRange.forEach((date) => {
        if (page !== ReportPage.REPORT) {
          return;
        }
        getActivity(personel.id, date, cookies.access_token)
          .then((result) => {
            const tableCell = document.getElementById(personel.id + '-' + adjustDate(date));
            if (result) {
              if (tableCell) {
                tableCell.innerText = result;
              }
            } else {
              if (tableCell) {
                tableCell.innerText = '-';
              }
            }
            reportCounter = reportCounter + 1;
            const reportPercent = Math.round((reportCounter / totalReport) * 100);
            const reportProgress = document.getElementById('report_progress');
            reportProgress.innerHTML = ' ... ' + reportPercent + '%';
            if (reportPercent >= 100) {
              reportProgress.innerHTML = '';
            }
            console.log('progress report is ' + reportPercent + '%');
          })
          .catch((error) => {
            // Handle any errors that occurred during the API request
            //console.error(error);
          });
      });
    });
  };

  const reportLoaded = document.getElementById('report_loaded');
  if (reportLoaded) {
    getReportData();
  } else {
    console.log('Report is not loaded');
  }

  let CurrentPage: FC = Form;
  if (page === ReportPage.REPORT && submitted) {
    CurrentPage = Report;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-0 dark:text-white lg:px-4 lg:py-0">
      <div className="rounded-xl border border-gray-100 bg-white shadow transition hover:border-white hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:shadow-gray-800 dark:hover:border-gray-600">
        <div className="flex flex-col gap-8 dark:text-white">
          <CurrentPage />
        </div>
      </div>
    </div>
  );
};

export default ReportingPage;

import type { ReactNode } from 'react';
import ActivitiesPage from './pages/Activities';
import DashboardPage from './pages/Dashboard';
import { HistoryPage } from './pages/History';
import MyCalendarPage from './pages/MyCalendar';
import { MyHistoryPage } from './pages/MyHistory';
import { PersonelSchedulePage } from './pages/PersonelSchedule';
import PersonnelHourUsagePage from './pages/PersonnelHourUsage';
import ReportingPage from './pages/Reporting';

export type ComponentCardItem = {
  className: string;
  images: { light: string; dark: string };
};

export type RouteProps = {
  href: string;
  component: ReactNode;
};

export const routes: RouteProps[] = [
  {
    href: '/',
    component: <DashboardPage />,
  },
  {
    href: '/activities',
    component: <ActivitiesPage />,
  },
  {
    href: '/reporting',
    component: <ReportingPage />,
  },
  {
    href: '/personnel-hour-usage',
    component: <PersonnelHourUsagePage />,
  },
  {
    href: '/my-calendar',
    component: <MyCalendarPage />,
  },
  {
    href: '/my-history',
    component: <MyHistoryPage />,
  },
  {
    href: '/history',
    component: <HistoryPage />,
  },
  {
    href: '/personel-schedule',
    component: <PersonelSchedulePage />,
  },
];

import { FC, useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import { Button, Label, Table } from '../../lib';
import { getPersonelSchedule, getSearchPersonel } from '../../services/scheduler.service';
import {
  NonEvent,
  PersonelScheduleRequest,
  PersonelScheduleResponse,
  PersonelSearch,
  PersonelSearchRequest,
  PersonelSearchResponse,
} from '../../types';
import { log } from '../../utils/log.util';

export const PersonelSchedulePage: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<NonEvent[]>([]);
  const [cookies, removeCookie] = useCookies(['access_token']);
  const ref = useRef(true);
  const [suggestions, setSuggestions] = useState<PersonelSearch[]>([]);
  const [selectedPersonel, setSelectedPersonel] = useState<PersonelSearch>(null);
  const [selectedPersonelName, setSelectedPersonelName] = useState<string>('');

  const getList = async () => {
    if (!selectedPersonel) {
      swal('Error', 'Incomplete search input', 'error');
      return;
    }
    const params: PersonelScheduleRequest = {
      api_version: '1.0',
      action_type: 'my-team-member-schedule',
      token: cookies.access_token,
      parameters: {
        emp_id: selectedPersonel.id,
      },
    };
    log(params, 'getList-req');
    setList([]);
    setLoading(true);
    let list: PersonelScheduleResponse = null;
    try {
      list = await getPersonelSchedule(params);
    } catch (e) {
      log(e, 'getList-err');
      list = e;
    }
    setLoading(false);
    log(list, 'getList-res');
    if (list?.data?.activities && list?.data?.activities?.length != 0) {
      setList(list.data.activities);
    } else {
      if (!list.success) {
        if (list.message == 'Authorization failed') {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      } else {
        swal('Info', `No schedule found of ${selectedPersonel.name}`, 'warning');
      }
    }
  };

  const getSuggestionValue = (suggestion) => {
    log('getSuggestionValue', suggestion);
    setSelectedPersonel(suggestion);
    return suggestion.name;
  };

  const renderSuggestion = (suggestion) => (
    <span className="sugg-option">
      <span className="name">
        {suggestion.name} ({suggestion.position_name})
      </span>
    </span>
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChangeSuggestion = (ev, data) => {
    log('onChange', data);
    setSelectedPersonelName(data.newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const params: PersonelSearchRequest = {
      api_version: '1.0',
      action_type: 'personnel-search',
      token: cookies.access_token,
      parameters: {
        name: value,
        position: '',
      },
    };
    log(params, 'onSuggestionsFetchRequested-req');
    setLoading(true);
    let list: PersonelSearchResponse = null;
    try {
      list = await getSearchPersonel(params);
    } catch (e) {
      log(e, 'onSuggestionsFetchRequested-err');
      list = e;
    }
    setLoading(false);
    log(list, 'onSuggestionsFetchRequested-res');
    if (list?.data?.personnel && list?.data?.personnel.length != 0) {
      setSuggestions(list.data.personnel);
    } else {
      if (!list.success) {
        if (list.message == 'Authorization failed') {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    }
  };

  useEffect(() => {
    const firstRender = ref.current;
    if (firstRender) {
      ref.current = false;
    }
  });

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-0 dark:text-white lg:px-4 lg:py-0">
      <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
        Personel Schedule
      </h2>
      <div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="personal" value="Personel" />
          </div>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder: 'Type personel name',
              value: selectedPersonelName,
              onChange: onChangeSuggestion,
            }}
          />
        </div>
        <Button
          className="mb-3"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            getList();
          }}
        >
          Search
        </Button>
        <Table striped={true} className="">
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Type</Table.HeadCell>
            <Table.HeadCell>Location</Table.HeadCell>
            <Table.HeadCell>Date Start</Table.HeadCell>
            <Table.HeadCell>Date End</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {list.map((item, i) => (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {item.activity_name}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {item.activity_type}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {item.location}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {item.datetime_start}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {item.datetime_end}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

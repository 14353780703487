import React, { FC, Suspense, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { HiCalendar, HiDatabase, HiHome, HiLogout, HiMenuAlt1 } from 'react-icons/hi';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Avatar, Button, DarkThemeToggle, Navbar, Sidebar, Spinner } from '../lib';
import { routes } from './routes';

export const Root: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const mainRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [cookies, removeCookie] = useCookies(['access_token']);

  const [nameUser, setNameUser] = useState(() => {
    const saved = localStorage.getItem('user_login');
    const initialValue = JSON.parse(saved);
    return (initialValue && initialValue.name) || 'No Name';
  });

  const [pangkatUser, setPangkatUser] = useState(() => {
    const saved = localStorage.getItem('user_login');
    const initialValue = JSON.parse(saved);
    return (initialValue && initialValue.rank_func_id) || '0';
  });

  useEffect(() => {
    if (!cookies.access_token) {
      window.location.href = '/#/login';
      window.location.reload();
    }
  }, []);

  const logout = async () => {
    removeCookie('access_token', { path: '/' });
    window.location.href = '/#/login';
    window.location.reload();
  };

  return (
    <div className="flex h-screen w-full flex-col overflow-hidden">
      <Navbar fluid>
        <div className="flex items-center">
          <HiMenuAlt1
            className="mr-6 h-6 w-6 cursor-pointer text-gray-600 dark:text-gray-400"
            onClick={() => setCollapsed(!collapsed)}
          />
          <span className="text-xl font-semibold dark:text-white">VIVAT Scheduler</span>
        </div>
        <div className="flex items-center gap-2">
          <DarkThemeToggle />
          <Avatar className="ml-2" rounded={true}>
            <div className="font-medium dark:text-white">
              <div>{nameUser}</div>
            </div>
          </Avatar>
          <Button outline={false} color="light" onClick={logout} className="ml-4">
            <HiLogout className="mr-2 h-5 w-6" /> Logout
          </Button>
        </div>
      </Navbar>
      <div className="flex h-full overflow-hidden bg-white dark:bg-gray-900">
        <Sidebar collapsed={collapsed}>
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <Sidebar.Item
                icon={HiHome}
                as={Link}
                to="/"
                active={'/' === pathname}
                onClick={() => mainRef.current?.scrollTo({ top: 0 })}
              >
                Home
              </Sidebar.Item>

              <Sidebar.Collapse icon={HiCalendar} label="Team Schedule">
                <Sidebar.Item
                  as={Link}
                  to="/activities"
                  active={'/activities' === pathname}
                  onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                >
                  Schedule
                </Sidebar.Item>
                {pangkatUser >= 3 ? (
                  <React.Fragment>
                    <Sidebar.Item
                      as={Link}
                      to="/personel-schedule"
                      active={'/personel-schedule' === pathname}
                      onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                    >
                      Personnel Schedule
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/history"
                      active={'/history' === pathname}
                      onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                    >
                      Assignment History
                    </Sidebar.Item>
                    <Sidebar.Item
                      as={Link}
                      to="/personnel-hour-usage"
                      active={'/personnel-hour-usage' === pathname}
                      onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                    >
                      Hours Usage
                    </Sidebar.Item>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Sidebar.Collapse>
              <Sidebar.Collapse icon={HiCalendar} label="My Schedule">
                <Sidebar.Item
                  as={Link}
                  to="/my-calendar"
                  active={'/my-activities' === pathname}
                  onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                >
                  Schedule
                </Sidebar.Item>
                <Sidebar.Item
                  as={Link}
                  to="/my-history"
                  active={'/my-history' === pathname}
                  onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                >
                  Assignment History
                </Sidebar.Item>
              </Sidebar.Collapse>
              <Sidebar.Item
                icon={HiDatabase}
                as={Link}
                to="/reporting"
                active={'/reporting' === pathname}
                onClick={() => mainRef.current?.scrollTo({ top: 0 })}
              >
                Reporting
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
        <main className="flex-1 overflow-auto p-4" ref={mainRef}>
          <Suspense
            fallback={
              <div className="flex h-full items-center justify-center">
                <Spinner />
              </div>
            }
          >
            <Routes>
              {routes.map(({ href, component: Component }) => (
                <Route key={href} path={href} element={Component} />
              ))}
            </Routes>
          </Suspense>
        </main>
      </div>
    </div>
  );
};

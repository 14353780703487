import { FC, useRef } from 'react';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import { Button } from '../lib';
import { doEncrypt, doLogin } from '../services/scheduler.service';
import { EncryptRequest, EncryptResponse, LoginRequest, LoginResponse } from '../types';
import { log } from '../utils/log.util';

export const Root: FC = () => {
  const iEmailRef = useRef(null);
  const iPasswordRef = useRef(null);
  const [cookies, setCookie] = useCookies(['access_token']);

  const login = async () => {
    const email = iEmailRef.current.value;
    const password = iPasswordRef.current.value;

    const encryptParams: EncryptRequest = {
      api_version: '1.0',
      action_type: 'encrypt',
      parameters: {
        str: password,
      },
    };
    log(encryptParams, 'encrypt-req');
    const r: EncryptResponse = await doEncrypt(encryptParams);
    log(r, 'encrypt-res');

    const params: LoginRequest = {
      email,
      password: r.data.encrypted,
    };
    log(params, 'login-req');
    let response: LoginResponse = null;
    try {
      response = await doLogin(params);
    } catch (e) {
      log(e, 'login-err');
      response = e;
    }
    log(response, 'login-res');
    if (response?.data?.token && response?.data?.token.length != 0) {
      const expires = new Date();
      expires.setTime(expires.getTime() + 120 * 60 * 1000);
      setCookie('access_token', response.data.token, { path: '/', expires });
      localStorage.setItem('user_login', JSON.stringify(response.data.personnel));
      window.location.href = '/';
    } else {
      swal('Error', response.message, 'error');
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <a href="#" className="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
          VIVAT Scheduler
        </a>
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
          <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              Sign in to your account
            </h1>
            <div className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                  Your email
                </label>
                <input
                  ref={iEmailRef}
                  type="email"
                  name="email"
                  id="email"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                  placeholder="name@company.com"
                  // value="akhmad.baihaqi@an.tv"
                />
              </div>
              <div>
                <label htmlFor="password" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                  Password
                </label>
                <input
                  ref={iPasswordRef}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  // value="password"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 h-4 w-4 rounded border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                className="bg-primary-600 
                hover:bg-primary-700 
                focus:ring-primary-300 
                dark:bg-primary-600 
                dark:hover:bg-primary-700 
                dark:focus:ring-primary-800 
                w-full 
                rounded-lg 
                px-5 
                py-2.5 
                text-center 
                text-sm 
                font-medium 
                focus:outline-none 
                focus:ring-4 
                dark:text-white"
                onClick={(e) => {
                  login();
                }}
              >
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

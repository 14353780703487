// REF: https://date-fns.org/v2.10.0/docs/format
import { format } from 'date-fns-tz';
import moment from 'moment';

const timeZone = 'Asia/Jakarta';

export const formatDateTime = (value: any, f?: string) => {
  if (value === undefined || value === null) return '-';
  const iFormat = f || 'dd MMM yyyy hh:mm a';
  return format(new Date(value), iFormat, { timeZone });
};

export const formatDateTimeMinute = (value: any) => {
  if (value === undefined || value === null) return '-';
  return format(new Date(value), 'dd MMM yyyy hh:mm:ss a', { timeZone });
};

export const formatDate = (value: any, f?: string) => {
  if (value === undefined || value === null) return '-';
  const iFormat = f || 'dd MMM yyyy';
  return format(new Date(value), iFormat, { timeZone });
};

export const formatTime = (value: any) => {
  if (value === undefined || value === null) return '-';
  return format(new Date(value), 'HH:mm', { timeZone });
};

export const getDay = (value: string) => {
  const weekday = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRY', 'SAT'];
  const date = new Date(value);
  return weekday[date.getDay()];
};

export const getDate = (value: string) => {
  const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];
  const date = new Date(value);
  return `${monthNames[date.getMonth()]} ${date.getDate()}`;
};

export const getDateOnly = (datetime: string) => {
  if (datetime) {
    const s = datetime.split(' ');
    if (s.length == 2) {
      return s[0];
    }
  }
  return datetime;
};

export const getTimeOnly = (datetime: string) => {
  if (datetime) {
    const s = datetime.split(' ');
    if (s.length == 2) {
      return s[1];
    }
  }
  return datetime;
};

export const formatStartDate = (date: any) => {
  const startDate = new Date(date);
  startDate.setUTCHours(0, 0, 0, 0);
  return moment(startDate);
};

export const formatEndDate = (date: any) => {
  const endDate = new Date(date);
  endDate.setUTCHours(23, 59, 59, 999);
  return moment(endDate);
};

export const formatBirthOfDate = (value: any) => {
  const invalid = 'Invalid date';
  const dateBasic = value.toString().includes('-') && value.length <= 10;
  let dateOfBirth;
  if (dateBasic) {
    const dates = new Date(value.split('-').reverse().join('-'));
    dateOfBirth = moment(dates);
  } else if (value === invalid) {
    dateOfBirth = moment();
  } else {
    dateOfBirth = moment(value);
  }
  return dateOfBirth;
};
export const formatChangeBirthDate = (date: any) => {
  const newDates = formatStartDate(date);
  return moment(newDates);
};

import { FC, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiSearch } from 'react-icons/hi';
import { Modal, Table, TextInput } from '../../lib';
import { getSearchPersonel } from '../../services/scheduler.service';
import { Event, PersonelSearch, PersonelSearchRequest, PersonelSearchResponse } from '../../types';
import { formatDate } from '../../utils/date.util';
import { log } from '../../utils/log.util';

interface ModalProps {
  open: boolean;
  event: Event;
  onClose: () => void;
  onAddPersonel: (personel: PersonelSearch, startDate, endDate) => void;
  requiredPositionTitle: string;
  requiredPositionCode: string;
  startDate?: Date;
  endDate?: Date;
}

/*
interface ModalProps {
  open: boolean;
  onClose: () => void;
  onNewNonProgram: (
    activityId: number,
    note: string,
    personel: PersonelSearch,
    locationId: number,
    datetimeStart: string,
    datetimeEnd: string,
  ) => void;
  startDate?: Date;
  endDate?: Date;
}
*/

const ModalAddPersonel: FC<ModalProps> = (props) => {
  const { open, onClose, onAddPersonel, event, requiredPositionTitle, requiredPositionCode, startDate, endDate } =
    props;
  const [loading, setLoading] = useState<boolean>(false);
  const [iSearch, setISearch] = useState<string>('');
  const [personelsSearch, setPersonelsSearch] = useState<PersonelSearch[]>([]);
  const [listPersonelIds, setListPersonelIds] = useState<string[]>([]);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const inputRef = useRef<HTMLInputElement>(null);
  const [iDatetimeStart, setIDatetimeStart] = useState<Date>(null);
  const [iDatetimeEnd, setIDatetimeEnd] = useState<Date>(null);
  // const [iDatetimeStart, setIDatetimeStart] = useState<date>(event.datetime_start);
  // const [iDatetimeEnd, setIDatetimeEnd] = useState<date>(event.datetime_end);

  const [userEmail, setUserEmail] = useState(() => {
    const saved = localStorage.getItem('user_login');
    const initialValue = JSON.parse(saved);
    return (initialValue && initialValue.email) || '';
  });

  /*
  useEffect(() => {
    log(open, 'useEffect');
    if (open) {
      setListPersonelIds([]);
      setISearch('');
      setPersonelsSearch([]);
      searchPersonel(''); 
    }
  }, [open]);
  */

  useEffect(() => {
    log(open, 'useEffect');
    if (open) {
      if (startDate) {
        // setIDatetimeStart(startDate);
        // log('startDate', startDate);
        setIDatetimeStart(new Date(event.datetime_start));
      }
      if (endDate) {
        // setIDatetimeEnd(endDate);
        // log('endDate', endDate);
        setIDatetimeEnd(new Date(event.datetime_end));
      }
    }
  }, [open]);

  useEffect(() => {
    log(open, 'useEffect');
    if (open) {
      setListPersonelIds([]);
      setISearch('');
      setPersonelsSearch([]);
      searchPersonel('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      setISearch('');
    }
  }, [open]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setISearch(value);
    searchPersonel(value);
    /* 
    if (value.length >= 3) {
      searchPersonel(value);
    }
    */
  };

  const handleKeyDownSearch = (event) => {
    if (event.key === 'Enter') {
      log(iSearch, 'handleKeyDownSearch');
      searchPersonel(iSearch);
    }
  };

  const addListPersonalIds = (id: string) => {
    listPersonelIds.push(id);
    setListPersonelIds((state) => [...state]);
  };

  const searchPersonel = async (search: string) => {
    const params: PersonelSearchRequest = {
      api_version: '1.0',
      action_type: 'personnel-search',
      token: cookies.access_token,
      parameters: {
        name: search,
        position: '',
      },
    };
    log(params, 'listPersonel-req');
    setLoading(true);
    const list: PersonelSearchResponse = await getSearchPersonel(params);
    setLoading(false);
    log(list, 'listPersonel-res');
    if (list?.data?.personnel?.length != 0) {
      setPersonelsSearch(list.data.personnel);
    }
  };

  return (
    <Modal show={open} size="full" onClose={onClose}>
      <Modal.Header style={{ marginTop: '50px' }}>
        <div>
          New Personel for <strong>{requiredPositionTitle}</strong> in <strong>{event && event.program_name}</strong>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: '11pt' }}>
          <strong>Custom Assign Time</strong>
        </div>
        <div style={{ display: 'flex', fontSize: '11pt', marginTop: '10px', marginBottom: '30px' }}>
          {/* <div style={{ paddingTop: '10px' }}>
            <input type="checkbox" id="use_custom_assign_datetime" />
          </div> */}
          <div style={{ marginLeft: '10px' }}>
            {startDate ? (
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                selected={iDatetimeStart}
                onChange={(date) => setIDatetimeStart(date)}
                timeClassName={(time) => {
                  return time.getHours() > 12 ? 'text-success' : 'text-error';
                }}
              />
            ) : (
              <DatePicker
                showTimeSelect
                selected={iDatetimeStart}
                onChange={(date) => setIDatetimeStart(date)}
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                timeClassName={(time) => {
                  return time.getHours() > 12 ? 'text-success' : 'text-error';
                }}
              />
            )}
          </div>
          <div style={{ paddingTop: '9px', marginLeft: '10px', marginRight: '10px' }}>
            <strong>To</strong>
          </div>
          <div>
            {endDate ? (
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                selected={iDatetimeEnd}
                onChange={(date) => setIDatetimeEnd(date)}
                timeClassName={(time) => {
                  return time.getHours() > 12 ? 'text-success' : 'text-error';
                }}
              />
            ) : (
              <DatePicker
                showTimeSelect
                selected={iDatetimeEnd}
                onChange={(date) => setIDatetimeEnd(date)}
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                timeClassName={(time) => {
                  return time.getHours() > 12 ? 'text-success' : 'text-error';
                }}
              />
            )}
          </div>
        </div>
        <div className="mb-4 space-y-6">
          <TextInput
            ref={(input) => {
              if (input) {
                input.focus();
              }
            }}
            name="i-search"
            defaultValue={iSearch}
            icon={HiSearch}
            placeholder="John Doe"
            onChange={handleChangeSearch}
          />
          <Table striped={true} className="">
            <Table.Head>
              <Table.HeadCell>Full Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Position</Table.HeadCell>
              <Table.HeadCell>Level</Table.HeadCell>
              <Table.HeadCell>
                <span className="sr-only">Add</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {personelsSearch
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort the array by name in ascending order
                .map((item, i) => {
                  /*
                  if (item.email === userEmail) {
                    return null; // Hide the row if the email matches
                  }
                  */
                  return (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.name}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.email}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.position_name}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.user_level.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {!listPersonelIds.includes(item.id) ? (
                          <a
                            onClick={(e) => {
                              // console.log('====================================');
                              // console.log('item', item);
                              // console.log(
                              //   'assign_start',
                              //   formatDate(iDatetimeStart.toLocaleString(), 'yyyy-MM-dd hh:mm'),
                              // );
                              // console.log('assign_end', formatDate(iDatetimeEnd.toLocaleString(), 'yyyy-MM-dd hh:mm'));
                              // console.log('====================================');
                              addListPersonalIds(item.id);
                              onAddPersonel(
                                item,
                                formatDate(iDatetimeStart.toLocaleString(), 'yyyy-MM-dd HH:mm'),
                                formatDate(iDatetimeEnd.toLocaleString(), 'yyyy-MM-dd HH:mm'),
                              );
                            }}
                            className="cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                          >
                            Add
                          </a>
                        ) : (
                          ''
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const AddPersonel = Object.assign(ModalAddPersonel, {});

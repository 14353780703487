import axios from 'axios';

const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;
const iAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_ENDPOINT,
});

const iAxiosBlob = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_ENDPOINT,
});

iAxios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN_NAME)}`;
    const date = new Date();
    config.headers['X-offset-time'] = date.getTimezoneOffset() / 60;
    config.headers['X-selected-lang'] = localStorage.getItem('i18nextLng') || 'en';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

iAxios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = process.env.REACT_APP_DESTINATION_URL_LOGIN;
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    if (error.response.data.code === 401) {
      localStorage.clear();
      window.location.href = process.env.REACT_APP_DESTINATION_URL_LOGIN;
    }
    return Promise.reject(error.response.data);
  },
);

iAxiosBlob.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN_NAME)}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

iAxiosBlob.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = process.env.REACT_APP_DESTINATION_URL_LOGIN;
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    if (error.response.data.code === 401) {
      window.location.href = process.env.REACT_APP_DESTINATION_URL_LOGIN;
    }
    return Promise.reject(error.response.data);
  },
);

const get = async (url: string, headers?: any): Promise<any> => {
  const request = iAxios.get(url, headers);
  return request;
};

const post = async (url: string, body: any, headers?: any): Promise<any> => {
  const request = iAxios.post(url, body, headers);
  return request;
};

const put = async (url: string, body?: any, headers?: any): Promise<any> => {
  const request = iAxios.put(url, body, headers);
  return request;
};

const del = async (url: string, body?: any, headers?: any): Promise<any> => {
  const request = iAxios.delete(url, headers);
  return request;
};

const requestCallerBlob = (req: Request): Promise<any> => {
  return fetch(req)
    .then((resp) => {
      if (resp.status === 200 || resp.status === 201) {
        Promise.resolve(resp);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const postBlob = async (url: string, body: any, headers?: any): Promise<any> => {
  const request = iAxiosBlob.post(url, body, headers);
  return request;
};

const putBlob = async (url: string, body?: any, headers?: any): Promise<any> => {
  const request = new Request(url, {
    headers,
    body,
    method: 'PUT',
  });
  return requestCallerBlob(request);
};

export default class AxiosUtils {
  baseUrl = '';
  headers: any = {};

  // tslint:disable-next-line: ban-types
  constructor(baseUrl: string, interceptor) {
    this.baseUrl = baseUrl;
    interceptor(this.headers);
  }

  get = (url: string, headers?: any) => {
    const headerConfig = Object.assign({}, headers, this.headers);
    return get(url, headerConfig);
  };

  post = async (url: string, body: any, headers?: any) => {
    const headerConfig = Object.assign({}, headers, this.headers);
    return post(url, body, headerConfig);
  };

  put = (url: string, body: any, headers?: any) => {
    const headerConfig = Object.assign({}, headers, this.headers);
    return put(url, body, headerConfig);
  };

  delete = (url: string, body: any, headers?: any) => {
    const headerConfig = Object.assign({}, headers, this.headers);
    return del(url, body, headerConfig);
  };

  postBlob = (url: string, body: any, headers?: any) => {
    const headerConfig = Object.assign({}, headers, this.headers);
    return postBlob(url, body, headerConfig);
  };

  putBlob = (url: string, body: any, headers?: any) => {
    const headerConfig = Object.assign({}, headers, this.headers);
    return putBlob(url, body, headerConfig);
  };
}

import type { FC } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Root as DashboardView } from './dashboard/Root';
import { Root as FlowbiteView } from './docs/Root';
import './index.css';
import { Flowbite } from './lib/components';
import { Root as LoginView } from './login/Root';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  const theme = {
    sidebar: {
      root: {
        base: 'h-full bg-inherit',
        inner: 'h-full overflow-y-auto overflow-x-hidden rounded bg-inherit py-4 px-3',
      },
    },
  };

  const FlowbiteDocsView: FC = () => {
    return (
      <Flowbite theme={{ theme }}>
        <BrowserRouter>
          <FlowbiteView />
        </BrowserRouter>
      </Flowbite>
    );
  };

  const FlowbiteDashboardView: FC = () => {
    return (
      <CookiesProvider>
        <Flowbite theme={{ theme }}>
          <HashRouter>
            <DashboardView />
          </HashRouter>
        </Flowbite>
      </CookiesProvider>
    );
  };

  const FlowbiteLoginView: FC = () => {
    return (
      <CookiesProvider>
        <Flowbite theme={{ theme }}>
          <LoginView />
        </Flowbite>
      </CookiesProvider>
    );
  };

  const pathname = window.location.hash;
  if (pathname.includes('#/docs')) {
    root.render(<FlowbiteDocsView />);
  } else if (pathname.includes('#/login')) {
    root.render(<FlowbiteLoginView />);
  } else {
    root.render(<FlowbiteDashboardView />);
  }
}

import React, { FC, useEffect, useRef, useState } from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight, HiOutlineExclamationCircle, HiPlus } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';
import { Alert, Badge, Breadcrumb, Button, Label, Modal, Select, Table, Timeline } from '../../lib';

import swal from 'sweetalert';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import {
  deletePersonel,
  deletePersonelNonProgram,
  getListEvents,
  getListNonEvents,
  getListPersonel,
  getListPersonelNonProgram,
  insertPersonel,
  insertPersonelNonProgram,
  saveProgramBackdateNote,
} from '../../services/scheduler.service';
import {
  ActivityPage,
  Event,
  EventCalendar,
  EventListRequest,
  EventListResponse,
  NonEvent,
  NonEventListRequest,
  NonEventListResponse,
  Personel,
  PersonelAddRequest,
  PersonelAddResponse,
  PersonelListRequest,
  PersonelListResponse,
  PersonelNonProgramAddRequest,
  PersonelNonProgramAddResponse,
  PersonelNonProgramListRequest,
  PersonelNonProgramListResponse,
  PersonelRemoveRequest,
  PersonelRemoveResponse,
  PersonelSearch,
  SaveProgramBackdateNote,
  SaveProgramBackdateNoteRequest,
  SaveProgramBackdateNoteResponse,
} from '../../types';
import { randomColor } from '../../utils/common.util';
import { formatDateTime, getDateOnly, getTimeOnly } from '../../utils/date.util';
import { log } from '../../utils/log.util';
import { AddPersonel } from '../modals/AddPersonel';
import { AddPersonelNonProgram } from '../modals/AddPersonelNonProgram';
import { NewNonProgram } from '../modals/NewNonProgram';

let calendarStart = null;
let calendarEnd = null;
let currentMonth = -1;
let currentDateStart = null;
let currentDateEnd = null;
let personel: Personel = null;
let personelNonProgram: Personel = null;

const ActivitiesPage: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<ActivityPage>(ActivityPage.SELECT_MONTH);
  const [backgroundCalendar, setBackgroundCalendar] = useState<EventCalendar[]>([]);
  const [eventsCalendar, setEventsCalendar] = useState<EventCalendar[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [nonEvents, setNonEvents] = useState<NonEvent[]>([]);
  const [personels, setPersonels] = useState<Personel[]>([]);
  const [personelsNonProgram, setPersonelsNonProgram] = useState<Personel[]>([]);
  const [initialDate, setInitialDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event>(null);
  const [selectedNonEvent, setSelectedNonEvent] = useState<NonEvent>(null);
  const [selectMonth, setSelectMonth] = useState<string>('01');
  const [selectYear, setSelectYear] = useState<string>('2023');
  const [selectStatus, setSelectStatus] = useState<string>('7');
  const [modalAddPersonel, setModalAddPersonel] = useState<boolean>(false);
  const [modalAddPersonelNonProgram, setModalAddPersonelNonProgram] = useState<boolean>(false);
  const [modalConfirmRemovePersonel, setModalConfirmRemovePersonel] = useState<boolean>(false);
  const [modalConfirmRemovePersonelNonProgram, setModalConfirmRemovePersonelNonProgram] = useState<boolean>(false);
  const [modalPickDate, setModalPickDate] = useState<boolean>(false);
  const [modalNewNonProgram, setModalNewNonProgram] = useState<boolean>(false);
  const [modalFilter, setModalFilter] = useState<boolean>(false);
  const [modalNewNonProgramWDate, setModalNewNonProgramWDate] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const [requiredPositionTitle, setRequiredPositionTitle] = useState('');
  const [requiredPositionCode, setRequiredPositionCode] = useState('');
  const [isBackdateDialog, setIsBackdateDialogOpen] = useState(false);
  const [selectedBookingMemberID, setSelectedBookingMemberID] = useState<number>(0);
  const [backnote, setBacknote] = useState('');

  const calendar = useRef(null);

  const [isEventsCalendarInitialized, setEventsCalendarInitialized] = useState(false);
  const isInitializedRef = useRef(false);

  /*
    useEffect(() => {
      // Load data from local storage when the component mounts
      const storedEventsCalendar = localStorage.getItem('eventsCalendar');
      if (storedEventsCalendar) {
        setEventsCalendar(JSON.parse(storedEventsCalendar));
      }
      
      const storedEvents = localStorage.getItem('events');
      if (storedEvents) {
        setEvents(JSON.parse(storedEvents));
      }
    }, []);

    // Save data to local storage whenever the eventsCalendar state changes
    useEffect(() => {
      localStorage.setItem('eventsCalendar', JSON.stringify(eventsCalendar));
    }, [eventsCalendar]);
  */

  const [pangkatUser, setPangkatUser] = useState(() => {
    const saved = localStorage.getItem('user_login');
    const initialValue = JSON.parse(saved);
    return (initialValue && initialValue.rank_func_id) || '0';
  });

  const calendarDateClick = (e) => {
    log(e, 'calendarDateClick-date');
    setPage(ActivityPage.DETAIL_ACTIVITY);
    setSelectedDate(e.date);
    const d = moment(e.date).format('YYYY-MM-DD');
    listEventsInDay(d);
    listNonEventsInDay(d);
  };

  const calendarEventClick = (ev) => {
    log(ev.event?._instance?.range?.start, 'calendarEventClick-date');
    setPage(ActivityPage.DETAIL_ACTIVITY);
    setSelectedDate(ev.event?._instance?.range?.start);
    const d = moment(ev.event?._instance?.range?.start).format('YYYY-MM-DD');
    listEventsInDay(d);
    listNonEventsInDay(d);
  };

  const calendarGotoDate = (e) => {
    log({ selectMonth, selectYear });
    const calendarApi = calendar.current.getApi();
    calendarApi.gotoDate(`${selectYear}-${selectMonth}-01`);
    setModalPickDate(false);
  };

  const calendarFilter = (e) => {
    log('calendarFilter');
    setModalFilter(false);
    if (currentDateStart && currentDateEnd) {
      setEventsCalendar([]);
      listEvents(currentDateStart, currentDateEnd);
      listNonEvents(currentDateStart, currentDateEnd);
    }
  };

  const personalAssignedClick = (ev: Event) => {
    setPage(ActivityPage.PERSONAL_ASSIGNED);
    setSelectedEvent(ev);
    listPersonel(ev.id);
  };

  const personalAssignedNonProgramClick = (ev: NonEvent) => {
    setPage(ActivityPage.PERSONAL_ASSIGNED_NON_PROGRAM);
    setSelectedNonEvent(ev);
    listPersonelNonProgram(ev.activity_id, ev.datetime_start, ev.note);
  };

  const handleOpenBackdateDialog = (bookingMemberID, note) => {
    setIsBackdateDialogOpen(true);
    setSelectedBookingMemberID(bookingMemberID);
    setBacknote(note);
  };

  const handleCloseBackdateDialog = () => {
    setIsBackdateDialogOpen(false);
  };

  const removePersonel = async (personel: Personel) => {
    log(personel, 'removePersonel');
    const params: PersonelRemoveRequest = {
      api_version: '1.0',
      action_type: 'personnel-unassignment',
      token: cookies.access_token,
      parameters: {
        id: personel.id,
      },
    };
    log(params, 'removePersonel-req');
    try {
      const response: PersonelRemoveResponse = await deletePersonel(params);
      log(response, 'removePersonel-res');

      const updatedEvents = [];
      if (response.error_code === 0 && response.data !== null) {
        const newEvent = response.data.booking.activity;
        setSelectedEvent(newEvent);
        await listPersonel(selectedEvent.id);

        setEvents((prevEvents) => {
          const existingEventIndex = prevEvents.findIndex((event) => event.id === newEvent.id);

          if (existingEventIndex !== -1) {
            // If an event with the same ID exists, update it
            const updatedEvents = [...prevEvents];
            updatedEvents[existingEventIndex] = newEvent;
            return updatedEvents;
          } else {
            // If the event with the same ID does not exist, add the new event
            return [...prevEvents, newEvent];
          }
        });

        //setEventsCalendar([]);
        //listEvents(currentDateStart, currentDateEnd);
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
        await listPersonel(selectedEvent.id);
      }
    } catch (e) {
      log(e, 'removePersonel-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const removePersonelNonProgram = async (personel: Personel) => {
    log(personel, 'removePersonel');
    const params: PersonelRemoveRequest = {
      api_version: '1.0',
      action_type: 'remove-personnel-non-program-activity',
      token: cookies.access_token,
      parameters: {
        id: personel.id,
      },
    };
    log(params, 'removePersonelNonProgram-req');
    try {
      const response: PersonelRemoveResponse = await deletePersonelNonProgram(params);
      log(response, 'removePersonelNonProgram-res');
      if (response.error_code === 0) {
        listPersonelNonProgram(selectedNonEvent.activity_id, selectedNonEvent.datetime_start, selectedNonEvent.note);
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'removePersonelNonProgram-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const addPersonel = async (newPersonel: PersonelSearch, startDate: string, endDate: string) => {
    log(personel, 'addPersonel');
    const params: PersonelAddRequest = {
      api_version: '1.0',
      action_type: 'personnel-assignment',
      token: cookies.access_token,
      parameters: {
        booking_id: selectedEvent.id,
        emp_id: +newPersonel.id,
        member_type_id: 3,
        name: newPersonel.name,
        email: newPersonel.email,
        position: newPersonel.position_name,
        member_comp_type: newPersonel.company + '',
        position_id: newPersonel.position_id,
        section_id: newPersonel.section_id,
        division_id: newPersonel.division_id,
        directorate_id: newPersonel.directorate_id,
        department_id: newPersonel.department_id,
        pangkat: newPersonel.pangkat,
        role: requiredPositionCode,
        assign_start: startDate,
        assign_end: endDate,
      },
    };
    log(params, 'addPersonel-req');

    try {
      const updatedEvents = [];
      const response: PersonelAddResponse = await insertPersonel(params);
      log(response, 'addPersonel-res');
      if (response.error_code === 0 && response.data !== null) {
        const newEvent = response.data.booking.activity;
        setSelectedEvent(newEvent);

        await listPersonel(newEvent.id);

        setEvents((prevEvents) => {
          const existingEventIndex = prevEvents.findIndex((event) => event.id === newEvent.id);

          if (existingEventIndex !== -1) {
            // If an event with the same ID exists, update it
            const updatedEvents = [...prevEvents];
            updatedEvents[existingEventIndex] = newEvent;
            return updatedEvents;
          } else {
            // If the event with the same ID does not exist, add the new event
            return [...prevEvents, newEvent];
          }
        });
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
        await listPersonel(selectedEvent.id);
      }
    } catch (e) {
      log(e, 'addPersonel-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const submitProgramBackdateNote = async (paramSaveProgramBackdateNote: SaveProgramBackdateNote) => {
    log(paramSaveProgramBackdateNote, 'submitProgramBackdateNote');
    const params: SaveProgramBackdateNoteRequest = {
      api_version: '1.0',
      action_type: 'save-program-backdate-note',
      token: cookies.access_token,
      parameters: {
        id: paramSaveProgramBackdateNote.id,
        booking_id: paramSaveProgramBackdateNote.booking_id,
        backdate_note: paramSaveProgramBackdateNote.backdate_note,
      },
    };
    log(params, 'submitProgramBackdateNote-req');

    try {
      const updatedEvents = [];
      const response: SaveProgramBackdateNoteResponse = await saveProgramBackdateNote(params);
      log(response, 'submitProgramBackdateNote-res');
      if (response.error_code === 0 && response.data !== null) {
        personalAssignedClick(selectedEvent);
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
        await listPersonel(selectedEvent.id);
      }
    } catch (e) {
      log(e, 'submitProgramBackdateNote-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const newNonProgram = async (
    activityId: number,
    note: string,
    newPersonel: PersonelSearch,
    locationId: number,
    datetimeStart: string,
    datetimeEnd: string,
  ) => {
    log(personel, 'newNonProgram');
    const params: PersonelNonProgramAddRequest = {
      api_version: '1.0',
      action_type: 'add-personnel-non-program-activity',
      token: cookies.access_token,
      parameters: {
        emp_id: +newPersonel.id,
        nik: newPersonel.nik,
        name: newPersonel.name,
        email: newPersonel.email,
        position: newPersonel.position_name,
        location_id: locationId,
        datetime_start: datetimeStart,
        datetime_end: datetimeEnd,
        activity_id: activityId,
        note: note,
      },
    };
    try {
      log(params, 'newNonProgram-req');
      const response: PersonelNonProgramAddResponse = await insertPersonelNonProgram(params);
      log(response, 'newNonProgram-res');
      if (response.error_code === 0 && response.data !== null) {
        if (response.data.created) {
          const dateStartParts = datetimeStart.split(' ');
          const dateStart = dateStartParts[0];

          const dateEndParts = datetimeEnd.split(' ');
          const dateEnd = dateEndParts[0];
          setEventsCalendar([]);
          listEvents(currentDateStart, currentDateEnd);
          listNonEvents(dateStart, dateEnd);
          listNonEventsInDay(dateStart);
          listEventsInDay(dateStart);
          return true;
        }
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'newNonProgram-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
    return false;
  };

  const addPersonelNonProgram = async (
    note: string,
    newPersonel: PersonelSearch,
    locationId: number,
    datetimeStart: string,
    datetimeEnd: string,
  ) => {
    log(newPersonel, 'addPersonelNonProgram');
    const params: PersonelNonProgramAddRequest = {
      api_version: '1.0',
      action_type: 'add-personnel-non-program-activity',
      token: cookies.access_token,
      parameters: {
        emp_id: +newPersonel.id,
        nik: newPersonel.nik,
        name: newPersonel.name,
        email: newPersonel.email,
        position: newPersonel.position_name,
        location_id: locationId,
        datetime_start: datetimeStart,
        datetime_end: datetimeEnd,
        activity_id: selectedNonEvent.activity_id,
        note: note,
      },
    };
    try {
      log(params, 'addPersonelNonProgram-req');
      const response: PersonelNonProgramAddResponse = await insertPersonelNonProgram(params);
      log(response, 'addPersonelNonProgram-res');
      if (response.error_code === 0 && response.data !== null) {
        if (response.data.created) {
          listPersonelNonProgram(selectedNonEvent.activity_id, selectedNonEvent.datetime_start, selectedNonEvent.note);
          return true;
        }
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'addPersonelNonProgram-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
    return false;
  };

  const onCloseAddPersonel = () => {
    setModalAddPersonel(false);
  };

  const onCloseAddPersonelNonProgram = () => {
    setModalAddPersonelNonProgram(false);
  };

  const monthHandleChange = (e) => {
    setSelectMonth(e.target.value);
  };

  const statusHandleChange = (e) => {
    setSelectStatus(e.target.value);
  };

  const yearHandleChange = (e) => {
    setSelectYear(e.target.value);
  };

  const renderEventContent = (ev) => {
    return (
      <React.Fragment>
        <div
          className="overflow-hidden whitespace-nowrap px-1"
          data-tooltip-id={ev.event.title}
          data-tooltip-content={`${ev.event.extendedProps.category} - ${ev.event.title}`}
        >
          <b>{ev.timeText}</b> <span className="ml-1">{ev.event.title}</span>
        </div>
        <Tooltip id={ev.event.title} />
      </React.Fragment>
    );
  };

  const listEventsInDay = async (date) => {
    let status = 7;
    if (selectStatus) {
      status = +selectStatus;
    }
    const params: EventListRequest = {
      api_version: '1.0',
      action_type: 'activity',
      token: cookies.access_token,
      parameters: {
        datetime_start: `${date} 00:00`,
        datetime_end: `${date} 23:59`,
        status,
      },
    };
    log(params, 'listEventsInDay-req');
    try {
      setLoading(true);
      const list: EventListResponse = await getListEvents(params);
      setLoading(false);
      log(list, 'listEventsInDay-res');

      setEvents([]);
      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.activities?.length != 0) {
          setEvents(list.data.activities);
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'listEventsInDay-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const listNonEventsInDay = async (date) => {
    const params: NonEventListRequest = {
      api_version: '1.0',
      action_type: 'non-program-activity',
      token: cookies.access_token,
      parameters: {
        date_start: `${date}`,
        date_end: `${date}`,
      },
    };
    log(params, 'listNonEventsInDay-req');
    setLoading(true);
    try {
      const list: NonEventListResponse = await getListNonEvents(params);
      setLoading(false);
      log(list, 'listNonEventsInDay-res');
      setNonEvents([]);
      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.activities) {
          if (list?.data?.activities?.length != 0) {
            setNonEvents(list.data.activities);
          }
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'listNonEventsInDay-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const listEvents = async (startDate, lastDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const lastDateObj = new Date(lastDate);

    while (currentDate <= lastDateObj) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const requests = dates.map(async (date) => {
      const status = selectStatus ? +selectStatus : 7;

      const params = {
        api_version: '1.0',
        action_type: 'activity',
        token: cookies.access_token,
        parameters: {
          datetime_start: `${date} 00:00`,
          datetime_end: `${date} 23:59`,
          status,
        },
      };

      log(params, 'listEvents-req');

      try {
        const list = await getListEvents(params);
        log(list, 'listEvents-res');

        if (list.error_code === 0 && list.data !== null) {
          if (list?.data?.activities && list.data.activities.length !== 0) {
            setEvents((prevEvents) => prevEvents.concat(list.data.activities));

            const ec = list.data.activities
              .filter((ev) => ev.total_manpower !== 0) // Exclude activities with total_manpower equal to 0
              .map((ev) => {
                let color;

                if (ev.allocated === 0) {
                  color = 'red';
                } else if (ev.allocated < ev.total_manpower) {
                  color = 'orange';
                } else if (ev.allocated >= ev.total_manpower) {
                  color = 'green';
                } else {
                  color = randomColor(100); // Default color
                }

                return {
                  start: ev.datetime_start,
                  end: ev.datetime_end,
                  id: ev.id,
                  title: `${ev.allocated}/${ev.total_manpower} ${ev.program_name}`,
                  color: color,
                  category: 'Program',
                };
              });

            setEventsCalendar((prevEventsCalendar) => prevEventsCalendar.concat(ec));
          }
        } else {
          if (list.error_code === 4006) {
            removeCookie('access_token', { path: '/' });
            window.location.href = '/#/login';
            window.location.reload();
          } else {
            swal('Error', list.message, 'error');
          }
        }
      } catch (e) {
        log(e, 'listEvents-err');
        if (e.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        }
      }
    });

    setLoading(true);
    await Promise.all(requests);
  };

  const listNonEvents = async (startDate, lastDate) => {
    const params: NonEventListRequest = {
      api_version: '1.0',
      action_type: 'non-program-activity',
      token: cookies.access_token,
      parameters: {
        date_start: `${startDate}`,
        date_end: `${lastDate}`,
      },
    };
    log(params, 'listNonEvents-req');
    setLoading(true);
    try {
      const list: NonEventListResponse = await getListNonEvents(params);
      setLoading(false);
      log(list, 'listNonEvents-res');

      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.activities && list?.data?.activities.length != 0) {
          setNonEvents(list.data.activities);
          const ec: EventCalendar[] = list.data.activities.map((ev: NonEvent) => {
            return {
              start: ev.datetime_start,
              end: ev.datetime_end,
              id: ev.activity_id,
              title: ev.activity,
              color: 'lightpurple',
              category: 'Non-Program',
            };
          });
          setEventsCalendar((current) => [...current, ...ec]);
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'listNonEvents-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const listPersonel = async (bookingId: number) => {
    const params: PersonelListRequest = {
      api_version: '1.0',
      action_type: 'activity-personnel',
      token: cookies.access_token,
      parameters: {
        booking_id: bookingId,
      },
    };
    log(params, 'listPersonel-req');
    setPersonels([]);
    setLoading(true);
    try {
      const list: PersonelListResponse = await getListPersonel(params);
      setLoading(false);
      log(list, 'listPersonel-res');
      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.members?.length != 0) {
          setPersonels(list.data.members);
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'listPersonel-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const listPersonelNonProgram = async (activityId: number, date: string, note: string) => {
    const params: PersonelNonProgramListRequest = {
      api_version: '1.0',
      action_type: 'non-program-activity-personnel',
      token: cookies.access_token,
      parameters: {
        activity_id: activityId,
        date,
        note,
      },
    };
    log(params, 'listPersonelNonProgram-req');
    setPersonels([]);
    setLoading(true);
    try {
      const list: PersonelNonProgramListResponse = await getListPersonelNonProgram(params);
      setLoading(false);
      log(list, 'listPersonelNonProgram-req');
      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.personnels?.length != 0) {
          setPersonelsNonProgram(list.data.personnels);
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'listPersonelNonProgram-res-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    log({ y, m: m + 1 });
  }, []);

  const handleMonthChange = async (payload) => {
    if (calendarStart != payload.startStr) {
      log({ calendarStart, calendarEnd, payload }, 'handleMonthChange');
      calendarStart = payload.startStr;
      calendarEnd = payload.endStr;
      if (payload.view.type === 'dayGridMonth') {
        const m = new Date((payload.start.getTime() + payload.end.getTime()) / 2);
        log(m, 'handleMonthChange-mid');
        const year = m.getFullYear(),
          month = m.getMonth() + 1;
        if (currentMonth != month) {
          currentMonth = month;
          setInitialDate(m);
          currentDateStart = moment(payload.start).format('YYYY-MM-DD');
          currentDateEnd = moment(payload.end).format('YYYY-MM-DD');
          setEventsCalendar([]);
          listEvents(currentDateStart, currentDateEnd);
          listNonEvents(currentDateStart, currentDateEnd);
        }
      }
    }
  };

  const SelectMonth = () => {
    return (
      <div className="mx-5 my-3">
        <FullCalendar
          ref={calendar}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'newNonProgram filter today,goToDate dayGridMonth,prev,next',
          }}
          customButtons={{
            goToDate: {
              text: 'Pick Date',
              click: (e) => {
                setModalPickDate(true);
              },
            },
            newNonProgram: {
              text: '+ New Non-Program',
              click: (e) => {
                if (pangkatUser < 3) {
                  swal('Warning', 'Only SPV Up can do this action', 'warning');
                } else {
                  setModalNewNonProgram(true);
                }
              },
            },
            filter: {
              text: 'Filter',
              click: (e) => {
                setModalFilter(true);
              },
            },
          }}
          initialView="dayGridMonth"
          initialDate={initialDate}
          events={eventsCalendar}
          editable={true}
          eventContent={renderEventContent}
          dateClick={calendarDateClick}
          eventClick={calendarEventClick}
          height={900}
          contentHeight={780}
          aspectRatio={3}
          nowIndicator={true}
          dayMaxEvents={2}
          navLinks={true}
          eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
          eventDisplay="block"
          datesSet={handleMonthChange}
          views={{
            dayGridMonth: { buttonText: 'Month' },
            timeGridWeek: { buttonText: 'Week', visible: false }, // Hide week view
            timeGridDay: { buttonText: 'Day', visible: false }, // Hide day view
          }}
          buttonText={{
            today: 'Today',
          }}
        />
      </div>
    );
  };

  const AddProgramBlackdateNoteDialog = ({ onClose }) => {
    const [backnoteContent, setBacknoteContent] = useState('');

    const handleBacknoteChange = (event) => {
      setBacknoteContent(event.target.value);
    };

    const handleSaveBacknote = () => {
      const paramBackdateNote = {
        id: selectedBookingMemberID ? selectedBookingMemberID : 0,
        booking_id: selectedEvent ? selectedEvent.id : 0,
        backdate_note: backnoteContent,
      };
      submitProgramBackdateNote(paramBackdateNote);
      handleCloseBackdateDialog();
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-neutral-700 bg-opacity-10">
        <div className="rounded-lg bg-white p-6">
          <textarea
            className="mb-4 h-96 w-96 resize-none rounded border border-gray-300 p-2"
            placeholder="Enter your backnote here..."
            onChange={handleBacknoteChange}
          >
            {backnote}
          </textarea>
          <div></div>
          <button className="rounded bg-green-500 px-4 py-2 text-white" onClick={handleSaveBacknote}>
            Save Backnote
          </button>
          &nbsp;&nbsp;&nbsp;
          <button className="rounded bg-red-500 px-4 py-2 text-white" onClick={handleCloseBackdateDialog}>
            Close
          </button>
        </div>
      </div>
    );
  };

  const DetailActivity = () => {
    return (
      <div className="mx-5 my-3">
        <h2 className="mb-2 mb-3 text-3xl text-gray-900 dark:text-white lg:text-3xl lg:leading-snug">
          <span
            className="m-auto inline-block cursor-pointer pr-2 align-middle"
            onClick={(e) => {
              setPage(ActivityPage.SELECT_MONTH);
            }}
          >
            <HiArrowNarrowLeft className="h-6 w-6" />
          </span>
          {selectedDate && moment(selectedDate).format('dddd, DD MMMM YYYY')}
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Programs</h5>
            <Timeline className="ml-2 mt-3">
              {events &&
                events.map((item, i) => {
                  if (item.total_manpower === 0) {
                    return null; // Don't render the item
                  }

                  return (
                    <Timeline.Item className="mb-5" key={i}>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time>
                          {`${item.datetime_start.split(' ')[1]} - ${item.datetime_end.split(' ')[1]}`} WIB &#x2022;{' '}
                          {item.company_name}
                        </Timeline.Time>
                        <Timeline.Title>{`${item.program_name} - ${item.program_eps} `}</Timeline.Title>
                        {/*<span className="text-sm text-gray-400 dark:text-gray-500">{item.program_eps}</span><br/>
                        <span className="text-sm text-gray-400 dark:text-gray-500">{item.programme_type}</span>*/}
                        <Timeline.Body></Timeline.Body>
                        <div className="flex flex-wrap gap-2">
                          <Button outline={true} color="gray" onClick={(e) => personalAssignedClick(item)}>
                            {`${item.allocated}/${item.total_manpower}`} Personal Assigned
                            <HiArrowNarrowRight className="ml-2 h-3 w-3" />
                          </Button>
                        </div>
                      </Timeline.Content>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </div>
          <div>
            <div className="inline-flex">
              <h5 className="mb-auto mr-3 mt-auto text-xl font-bold leading-none text-gray-900 dark:text-white">
                Non-Programs
              </h5>
              <Button
                outline={true}
                color="gray"
                onClick={(e) => {
                  if (pangkatUser < 3) {
                    swal('Warning', 'Only SPV Up can do this action', 'warning');
                  } else {
                    setModalNewNonProgramWDate(true);
                  }
                }}
              >
                <HiPlus className="mr-2 h-3 w-3" />
                New
              </Button>
            </div>
            <Timeline className="ml-2 mt-3">
              {nonEvents &&
                nonEvents.map((item, i) => (
                  <Timeline.Item className="mb-5">
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>
                        {getDateOnly(item.datetime_start) == getDateOnly(item.datetime_end)
                          ? `${getTimeOnly(item.datetime_start)} til ${getTimeOnly(item.datetime_end)}`
                          : ''}
                        {getDateOnly(item.datetime_start) != getDateOnly(item.datetime_end)
                          ? `${getTimeOnly(item.datetime_start)} 
                          til ${formatDateTime(item.datetime_end, 'MMMM d, h:mm aa')}`
                          : ''}
                      </Timeline.Time>
                      <Timeline.Title>{item.activity}</Timeline.Title>
                      <span className="text-sm text-gray-400 dark:text-gray-500">{item.location}</span>
                      <Timeline.Body></Timeline.Body>
                      <div className="flex flex-wrap gap-2">
                        <Button outline={true} color="gray" onClick={(e) => personalAssignedNonProgramClick(item)}>
                          Personal Assigned
                          <HiArrowNarrowRight className="ml-2 h-3 w-3" />
                        </Button>
                      </div>
                    </Timeline.Content>
                  </Timeline.Item>
                ))}
            </Timeline>
          </div>
        </div>
      </div>
    );
  };

  const PersonalAssigned = () => {
    const backdateAssignment = (assignedDate, programEndDate) => {
      // Convert the date strings to Date objects
      const assignedAtDate = new Date(assignedDate);
      const datetimeEndDate = new Date(programEndDate);
      return assignedAtDate > datetimeEndDate;
    };

    return (
      <div className="mx-5 my-3">
        <div className="mb-3 flex">
          <div>
            <h5 className="ml-8 text-xl leading-none text-gray-900 dark:text-white">Program</h5>
            <div className="flex">
              <h2 className="mb-2 text-3xl text-gray-900 dark:text-white lg:text-3xl lg:leading-snug">
                <span
                  className="m-auto inline-block cursor-pointer pr-2 align-middle"
                  onClick={(e) => {
                    setPage(ActivityPage.DETAIL_ACTIVITY);
                  }}
                >
                  <HiArrowNarrowLeft className="h-6 w-6" />
                </span>
                {selectedEvent && `${selectedEvent.program_name}`}
                <div style={{ fontSize: '50%', color: 'gray', marginLeft: '38px' }}>
                  {selectedEvent.program_eps} &#x2022; {selectedEvent.programme_type}
                </div>
              </h2>
            </div>
            <div className="flex flex-wrap gap-2" style={{ marginTop: '10px' }}>
              {Object.entries(selectedEvent?.allocated_role || {}).map(([key, value], index) => {
                if (key === 'undefined' || key === 'other' || selectedEvent?.[key] === 0) {
                  return null; // Skip the iteration for "undefined", "other" badges, and when value is 0
                }

                const displayKey = key === 'gensetop' ? 'ME' : key;
                const badgeId = `badge-${selectedEvent?.id}-${key}`; // Generate the badge ID

                if (displayKey === 'other') {
                  return null; // Skip rendering the badge when displayKey is 'other'
                }

                return (
                  <Badge
                    key={key}
                    id={badgeId} // Assign the badge ID
                    className={`${
                      value < selectedEvent?.[key]
                        ? value === 0
                          ? 'cursor-pointer bg-red-200'
                          : 'cursor-pointer bg-yellow-200'
                        : value >= selectedEvent?.[key]
                        ? 'cursor-pointer bg-green-200'
                        : ''
                    }`}
                    onClick={
                      selectedEvent?.[key] !== 0
                        ? () => {
                            if (pangkatUser < 3) {
                              swal('Warning', 'Only SPV Up can do this action', 'warning');
                            } else if (value >= selectedEvent?.[key]) {
                              swal('Warning', 'This role is already fully allocated', 'warning');
                            } else {
                              setModalAddPersonel(true);
                              setRequiredPositionTitle(displayKey);
                              setRequiredPositionCode(key.toLowerCase());
                            }
                          }
                        : null
                    }
                  >
                    {`${value} / ${selectedEvent?.[key] || 0} ${displayKey.toUpperCase()}`}
                  </Badge>
                );
              })}
              {/* Add 30 blank badges to compensate width*/}
              {Array.from({ length: 30 }).map((_, index) => (
                <Badge key={`blank-badge-${index}`} className="border-none bg-white">
                  &nbsp;&nbsp;&nbsp;
                </Badge>
              ))}
            </div>

            <Alert color="info" className="mt-2 p-3">
              <span>
                <span className="font-medium"> Required Manpower</span> (
                {`${selectedEvent.allocated}/${selectedEvent.total_manpower}`})
              </span>
            </Alert>
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <Table striped={true} className="">
            <Table.Head>
              <Table.HeadCell>Full Name</Table.HeadCell>
              {/* <Table.HeadCell>Email</Table.HeadCell> */}
              <Table.HeadCell>Start</Table.HeadCell>
              <Table.HeadCell>End</Table.HeadCell>
              {/*<Table.HeadCell>Position</Table.HeadCell>*/}
              <Table.HeadCell>Note</Table.HeadCell>
              <Table.HeadCell>
                <span className="sr-only">Edit</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {personels &&
                Object.entries(
                  personels.reduce((acc, item) => {
                    if (!acc[item.role]) {
                      acc[item.role] = [];
                    }
                    acc[item.role].push(item);
                    return acc;
                  }, {}),
                ).map(([role, items]) => (
                  <React.Fragment key={role}>
                    <Table.Row className="font-medium text-gray-900 dark:text-white">
                      <Table.Cell colSpan={5} style={{ backgroundColor: '#EFEFEF' }}>
                        <strong style={{ color: 'black' }}>
                          {window.atob(role).toUpperCase() === 'GENSETOP' ? 'ME' : window.atob(role).toUpperCase()}
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                    {(items as Array<any>).map((item, i) => (
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {item.name}
                        </Table.Cell>
                        {/* <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {item.email}
                        </Table.Cell> */}
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {item.assign_start}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {item.assign_end}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {backdateAssignment(item.assigned_at, selectedEvent.datetime_end) && (
                            <button
                              onClick={(e) => {
                                if (pangkatUser < 3) {
                                  swal('Warning', 'Only SPV Up can do this action', 'warning');
                                } else {
                                  handleOpenBackdateDialog(item.id, item.note);
                                }
                              }}
                              style={{ backgroundColor: item.note ? 'green' : '#F9A603' }}
                              className="cursor-pointer rounded px-3 py-1 font-medium text-white hover:bg-yellow-300 dark:bg-yellow-400 dark:text-white dark:hover:bg-yellow-300"
                            >
                              Backdate Note
                            </button>
                          )}
                          {isBackdateDialog && <AddProgramBlackdateNoteDialog onClose={handleCloseBackdateDialog} />}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          <button
                            onClick={(e) => {
                              if (pangkatUser < 3) {
                                swal('Warning', 'Only SPV Up can do this action', 'warning');
                              } else {
                                personel = item;
                                setModalConfirmRemovePersonel(true);
                              }
                            }}
                            className="cursor-pointer rounded bg-red-600 px-3 py-1 font-medium text-white hover:bg-yellow-500 dark:bg-red-600 dark:text-white dark:hover:bg-yellow-500"
                          >
                            Remove
                          </button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </React.Fragment>
                ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  };

  const PersonalAssignedNonProgram = () => {
    return (
      <div className="mx-5 my-3">
        <div className="mb-3 flex">
          <div>
            <h5 className="ml-8 text-xl leading-none text-gray-900 dark:text-white">Non-Program</h5>
            <h2 className="mb-2 text-3xl text-gray-900 dark:text-white lg:text-3xl lg:leading-snug">
              <span
                className="m-auto inline-block cursor-pointer pr-2 align-middle"
                onClick={(e) => {
                  setPage(ActivityPage.DETAIL_ACTIVITY);
                }}
              >
                <HiArrowNarrowLeft className="h-6 w-6" />
              </span>
              {selectedNonEvent.datetime_start}: {selectedNonEvent && selectedNonEvent.activity}
            </h2>
          </div>
          <Button
            className="mb-3 ml-auto"
            size="sm"
            onClick={(e) => {
              log('addPersonelNonProgram');
              setModalAddPersonelNonProgram(true);
            }}
          >
            New Personil
          </Button>
        </div>
        <Table striped={true}>
          <Table.Head>
            <Table.HeadCell>Full Name</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell>Position</Table.HeadCell>
            <Table.HeadCell>
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {personelsNonProgram && personelsNonProgram.length > 0 ? (
              personelsNonProgram.map((item, i) => (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {item.name}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {item.email}
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {item.position}
                  </Table.Cell>
                  <Table.Cell className="text-right">
                    <a
                      onClick={(e) => {
                        personelNonProgram = item;
                        setModalConfirmRemovePersonelNonProgram(true);
                      }}
                      className="cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >
                      Remove
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={4} className="text-center">
                  No data available.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  let CurrentPage = SelectMonth;

  if (page == ActivityPage.SELECT_MONTH) {
    console.log('you are now at SELECT_MONTH');
    CurrentPage = SelectMonth;
    console.log('eventsCalendar:');
    console.log(eventsCalendar);
    console.log('events:');
    console.log(events);
    console.log(selectedDate);
    if (eventsCalendar.length == 0) {
      console.log('Kosong');
    }
  } else if (page == ActivityPage.DETAIL_ACTIVITY) {
    console.log('you are now at DETAIL_ACTIVITY');
    CurrentPage = DetailActivity;
  } else if (page == ActivityPage.PERSONAL_ASSIGNED) {
    console.log('you are now at PERSONAL_ASSIGNED');
    console.log(personels);
    CurrentPage = PersonalAssigned;
  } else if (page == ActivityPage.PERSONAL_ASSIGNED_NON_PROGRAM) {
    console.log('you are now at PERSONAL_ASSIGNED_NON_PROGRAM');
    CurrentPage = PersonalAssignedNonProgram;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-0 dark:text-white lg:px-4 lg:py-0">
      <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
        Activities
      </h2>
      <div className="rounded-xl border border-gray-100 bg-white shadow transition hover:border-white hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:shadow-gray-800 dark:hover:border-gray-600">
        <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-900">
          <Breadcrumb.Item>
            <span className={`${page == ActivityPage.SELECT_MONTH ? 'text-teal-500' : ''}`}>Calendar</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className={`${page == ActivityPage.DETAIL_ACTIVITY ? 'text-teal-500' : ''}`}>Activities</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              className={`${
                page == ActivityPage.PERSONAL_ASSIGNED || page == ActivityPage.PERSONAL_ASSIGNED_NON_PROGRAM
                  ? 'text-teal-500'
                  : ''
              }`}
            >
              Personal Assigned
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="flex flex-col gap-8 dark:text-white">
          <CurrentPage />
        </div>
      </div>
      <AddPersonel
        onClose={onCloseAddPersonel}
        open={modalAddPersonel}
        event={selectedEvent}
        onAddPersonel={addPersonel}
        requiredPositionTitle={requiredPositionTitle}
        requiredPositionCode={requiredPositionCode}
        startDate={selectedDate}
        endDate={selectedDate}

        // startDate={new Date(selectedEvent.datetime_start)}
        // endDate={new Date(selectedEvent.datetime_end)}
        //xxx
      />
      <AddPersonelNonProgram
        onClose={onCloseAddPersonelNonProgram}
        open={modalAddPersonelNonProgram}
        event={selectedNonEvent}
        onAddPersonelNonProgram={addPersonelNonProgram}
      />
      <NewNonProgram
        onClose={() => {
          setModalNewNonProgram(false);
        }}
        open={modalNewNonProgram}
        onNewNonProgram={newNonProgram}
      />
      <NewNonProgram
        onClose={() => {
          setModalNewNonProgramWDate(false);
        }}
        open={modalNewNonProgramWDate}
        onNewNonProgram={newNonProgram}
        startDate={selectedDate}
        endDate={selectedDate}
      />
      <Modal
        show={modalConfirmRemovePersonel}
        size="md"
        popup={true}
        onClose={() => {
          setModalConfirmRemovePersonel(false);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to remove this person?
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={(e) => {
                  removePersonel(personel);
                  setModalConfirmRemovePersonel(false);
                }}
              >
                Yes, I'm sure
              </Button>
              <Button
                color="gray"
                onClick={(e) => {
                  setModalConfirmRemovePersonel(false);
                }}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalConfirmRemovePersonelNonProgram}
        size="md"
        popup={true}
        onClose={() => {
          setModalConfirmRemovePersonelNonProgram(false);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to remove this person?
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={(e) => {
                  removePersonelNonProgram(personelNonProgram);
                  setModalConfirmRemovePersonelNonProgram(false);
                }}
              >
                Yes, I'm sure
              </Button>
              <Button
                color="gray"
                onClick={(e) => {
                  setModalConfirmRemovePersonelNonProgram(false);
                }}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalPickDate}
        size="xl"
        popup={true}
        onClose={() => {
          setModalPickDate(false);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex text-center">
            <Select
              id="month"
              required={true}
              className="mr-2 flex-auto"
              value={selectMonth}
              onChange={monthHandleChange}
            >
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Select>
            <Select id="year" required={true} className="mr-2 flex-auto" value={selectYear} onChange={yearHandleChange}>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </Select>
            <Button type="submit" onClick={calendarGotoDate}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalFilter}
        size="xl"
        popup={true}
        onClose={() => {
          setModalFilter(false);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex">
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="endDate" value="Filter Status Program" />
              </div>
              <Select
                id="status"
                required={true}
                className="mb-3 ml-auto"
                value={selectStatus}
                onChange={statusHandleChange}
              >
                <option value="7">On Booking</option>
                <option value="9">Cancel</option>
                <option value="12">On Booking - Zero Assignment</option>
                <option value="13">On Booking - Incomplete Assignment</option>
                <option value="0">All</option>
              </Select>
              <Button type="submit" onClick={calendarFilter}>
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ActivitiesPage;

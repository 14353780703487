import moment from 'moment';
import { FC, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import { Button, Label, Table } from '../../lib';
import { getMyHistory } from '../../services/scheduler.service';
import { MyHistoryRequest, MyHistoryResponse, NonEvent } from '../../types';
import { formatDate } from '../../utils/date.util';
import { log } from '../../utils/log.util';

export const MyHistoryPage: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<NonEvent[]>([]);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const ref = useRef(true);
  const [iDatetimeStart, setIDatetimeStart] = useState<Date>(new Date());
  const [iDatetimeEnd, setIDatetimeEnd] = useState<Date>(new Date());

  const getList = async () => {
    if (!iDatetimeStart || !iDatetimeEnd) {
      swal('Error', 'Incomplete search input', 'error');
      return;
    }
    const dateStart = formatDate(iDatetimeStart, 'yyyy-MM-dd hh:mm');
    const dateEnd = formatDate(iDatetimeEnd, 'yyyy-MM-dd hh:mm');
    const params: MyHistoryRequest = {
      api_version: '1.0',
      action_type: 'my-history',
      token: cookies.access_token,
      parameters: {
        datetime_start: moment(dateStart).startOf('day').format('YYYY-MM-DD HH:mm'),
        datetime_end: moment(dateEnd).endOf('day').format('YYYY-MM-DD HH:mm'),
      },
    };
    log(params, 'getList-req');
    setList([]);
    setLoading(true);
    let list: MyHistoryResponse = null;
    try {
      list = await getMyHistory(params);
    } catch (e) {
      log(e, 'getList-err');
      list = e;
    }
    setLoading(false);
    log(list, 'getList-res');
    if (list?.data?.activities && list?.data?.activities?.length != 0) {
      setList(list.data.activities);
    } else {
      if (!list.success) {
        if (list.message == 'Authorization failed') {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      } else {
        swal('Info', `No schedule found for range date input`, 'warning');
      }
    }
  };

  useEffect(() => {
    const firstRender = ref.current;
    if (firstRender) {
      ref.current = false;
    }
  });

  const backdatedNote = (item) => {
    if (item.activity_type === 'program') {
      if (item.note !== '') {
        const remark =
          'Backdated :<br/>' +
          item.assigned_at +
          '<br/>' +
          item.assigned_by_name +
          ' (' +
          item.assigned_by_nik +
          ')' +
          '<br/>' +
          item.note +
          '<br/><br/>';
        return remark;
      }
    }
    return '';
  };

  const deletedNote = (item) => {
    if (item.deleted_at !== null) {
      const remark =
        'Deleted :<br/>' + item.deleted_at + '<br/>' + item.deleted_by_name + ' (' + item.deleted_by_nik + ')';
      return remark;
    }
    return '';
  };

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-0 dark:text-white lg:px-4 lg:py-0">
      <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
        My History
      </h2>
      <div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="startDate" value="Start Date" />
          </div>
          <DatePicker
            selected={iDatetimeStart}
            onChange={(date) => setIDatetimeStart(date)}
            dateFormat="MMMM d, yyyy"
            className="mb-3"
            placeholderText="Start Date"
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="endDate" value="End Date" />
          </div>
          <DatePicker
            selected={iDatetimeEnd}
            onChange={(date) => setIDatetimeEnd(date)}
            dateFormat="MMMM d, yyyy"
            className="mb-3"
            placeholderText="End Date"
          />
        </div>
        <Button
          className="mb-3"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            getList();
          }}
        >
          Search
        </Button>

        <Table striped={true} className="">
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Type</Table.HeadCell>
            <Table.HeadCell>Location</Table.HeadCell>
            <Table.HeadCell>Date Start</Table.HeadCell>
            <Table.HeadCell>Date End</Table.HeadCell>
            <Table.HeadCell>Note</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {list.map((item, i) => (
              <Table.Row
                className={`bg-${item.deleted ? 'red' : 'black'} dark:border-gray-700 dark:bg-gray-800`}
                key={i}
              >
                <Table.Cell
                  className={`whitespace-nowrap font-medium ${
                    item.deleted ? 'text-red-500' : 'text-gray-900 dark:text-white'
                  }`}
                >
                  {item.deleted ? <span style={{ color: 'red' }}>{item.activity_name}</span> : item.activity_name}
                </Table.Cell>
                <Table.Cell
                  className={`whitespace-nowrap font-medium ${
                    item.deleted ? 'text-red-500' : 'text-gray-900 dark:text-white'
                  }`}
                >
                  {item.activity_type}
                </Table.Cell>
                <Table.Cell
                  className={`whitespace-nowrap font-medium ${
                    item.deleted ? 'text-red-500' : 'text-gray-900 dark:text-white'
                  }`}
                >
                  {item.location}
                </Table.Cell>
                <Table.Cell
                  className={`whitespace-nowrap font-medium ${
                    item.deleted ? 'text-red-500' : 'text-gray-900 dark:text-white'
                  }`}
                >
                  {item.datetime_start}
                </Table.Cell>
                <Table.Cell
                  className={`whitespace-nowrap font-medium ${
                    item.deleted ? 'text-red-500' : 'text-gray-900 dark:text-white'
                  }`}
                >
                  {item.datetime_end}
                </Table.Cell>
                <Table.Cell
                  className={`whitespace-nowrap font-medium ${
                    item.deleted ? 'text-red-500' : 'text-gray-900 dark:text-white'
                  }`}
                >
                  <span
                    style={{ fontStyle: 'italic', color: 'blue' }}
                    dangerouslySetInnerHTML={{ __html: backdatedNote(item) }}
                  ></span>
                  {item.deleted_at !== null && (
                    <span
                      style={{ fontStyle: 'italic', color: 'blue' }}
                      dangerouslySetInnerHTML={{ __html: deletedNote(item) }}
                    ></span>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

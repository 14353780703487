import { FC, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiSearch, HiUserRemove } from 'react-icons/hi';
import { Badge, Button, Label, Modal, Select, Table, TextInput } from '../../lib';
import { getListLocation, getListNonProgram, getSearchPersonel } from '../../services/scheduler.service';
import {
  Location,
  LocationListRequest,
  LocationListResponse,
  MasterNonProgram,
  NonProgramListRequest,
  NonProgramListResponse,
  PersonelSearch,
  PersonelSearchRequest,
  PersonelSearchResponse,
} from '../../types';
import { formatDate } from '../../utils/date.util';
import { log } from '../../utils/log.util';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onNewNonProgram: (
    activityId: number,
    note: string,
    personel: PersonelSearch,
    locationId: number,
    datetimeStart: string,
    datetimeEnd: string,
  ) => void;
  startDate?: Date;
  endDate?: Date;
}

const ModalNewNonProgram: FC<ModalProps> = (props) => {
  const { open, onClose, onNewNonProgram, startDate, endDate } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [iSearch, setISearch] = useState<string>('');
  const [iActivityId, setIActivityId] = useState<number>(-1);
  const [iLocation, setILocation] = useState<number>(-1);
  const [iDatetimeStart, setIDatetimeStart] = useState<Date>(new Date());
  const [iDatetimeEnd, setIDatetimeEnd] = useState<Date>(new Date());
  const [personelsSearch, setPersonelsSearch] = useState<PersonelSearch[]>([]);
  const [personelsToAdd, setPersonelsToAdd] = useState<PersonelSearch[]>([]);
  const [listPersonelIds, setListPersonelIds] = useState<string[]>([]);
  const [listLocaltion, setListLocation] = useState<Location[]>([]);
  const [listMasterNonProgram, setListMasterNonProgram] = useState<MasterNonProgram[]>([]);
  const iNoteRef = useRef(null);
  const [cookies] = useCookies(['access_token']);

  useEffect(() => {
    log(open, 'useEffect');
    if (open) {
      if (startDate) {
        setIDatetimeStart(startDate);
      }
      if (endDate) {
        setIDatetimeEnd(endDate);
      }
      setListPersonelIds([]);
      setISearch('');
      setPersonelsSearch([]);
      setListLocation([]);
      listLocation();
      listNonProgram();
    }
  }, [open]);

  const handleChangeSearch = (event) => {
    setISearch(event.target.value);
  };

  const handleKeyDownSearch = (event) => {
    if (event.key === 'Enter') {
      log(iSearch, 'handleKeyDownSearch');
      searchPersonel(iSearch);
    }
  };

  const listLocation = async () => {
    const params: LocationListRequest = {
      api_version: '1.0',
      action_type: 'master-location',
      token: cookies.access_token,
      parameters: {
        name: '',
      },
    };
    log(params, 'listLocation-req');
    const list: LocationListResponse = await getListLocation(params);
    log(list, 'listLocation-res');
    if (list?.data?.locations && list?.data?.locations.length != 0) {
      setListLocation(list.data.locations);
    }
  };

  const listNonProgram = async () => {
    const params: NonProgramListRequest = {
      api_version: '1.0',
      action_type: 'master-non-program-activity',
      token: cookies.access_token,
      parameters: {
        name: '',
      },
    };
    log(params, 'listNonProgram-req');
    const list: NonProgramListResponse = await getListNonProgram(params);
    log(list, 'listNonProgram-res');
    if (list?.data?.activities && list?.data?.activities.length != 0) {
      setListMasterNonProgram(list.data.activities);
    }
  };

  const searchPersonel = async (search: string) => {
    const params: PersonelSearchRequest = {
      api_version: '1.0',
      action_type: 'personnel-search',
      token: cookies.access_token,
      parameters: {
        name: search,
        position: '',
      },
    };
    log(params, 'listPersonel-req');
    setLoading(true);
    const list: PersonelSearchResponse = await getSearchPersonel(params);
    setLoading(false);
    log(list, 'listPersonel-res');
    if (list?.data?.personnel && list?.data?.personnel.length != 0) {
      setPersonelsSearch(list.data.personnel);
    }
  };

  const add = (personel: PersonelSearch) => {
    log(personel, 'add');
    setPersonelsToAdd((current) => [...current, personel]);
  };

  const remove = (personel: PersonelSearch) => {
    log(personel, 'remove');
    setPersonelsToAdd(personelsToAdd.filter((item) => item.id !== personel.id));
  };

  const submit = async () => {
    if (personelsToAdd.length == 0) {
      return;
    }
    setLoading(true);
    const note = iNoteRef.current.value;
    const response = await Promise.all(
      personelsToAdd.map(async (item: PersonelSearch) => {
        try {
          onNewNonProgram(
            iActivityId,
            note,
            item,
            iLocation,
            formatDate(iDatetimeStart.toLocaleString(), 'yyyy-MM-dd hh:mm'),
            formatDate(iDatetimeEnd.toLocaleString(), 'yyyy-MM-dd hh:mm'),
          );
          return {
            status: 'success',
            name: item.name,
          };
        } catch (error) {
          log(error);
          return {
            status: 'failed',
            name: item.name,
          };
        }
      }),
    );
    log(response);
    setLoading(false);
    onClose();
  };

  return (
    <Modal show={open} size="full" onClose={onClose}>
      <Modal.Header>New Non-Program</Modal.Header>
      <Modal.Body>
        <div className="mb-4 space-y-6">
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <TextInput
                ref={(input) => {
                  if (input) {
                    input.focus();
                  }
                }}
                name={'i-search'}
                value={iSearch}
                icon={HiSearch}
                placeholder="John Doe"
                onChange={handleChangeSearch}
                onKeyDown={handleKeyDownSearch}
              />
              <Table striped={true} className="mt-3 table-fixed">
                <Table.Head>
                  <Table.HeadCell>Full Name</Table.HeadCell>
                  <Table.HeadCell>Email</Table.HeadCell>
                  <Table.HeadCell>Position</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Add</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {personelsSearch.map((item, i) => (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
                      <Table.Cell className="overflow-x-hidden whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.name}
                      </Table.Cell>
                      <Table.Cell className="overflow-x-hidden whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.email}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.position_name}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {!listPersonelIds.includes(item.id) ? (
                          <a
                            onClick={(e) => {
                              add(item);
                            }}
                            className="cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                          >
                            Add
                          </a>
                        ) : (
                          ''
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
            <div className="flex flex-col gap-4">
              <div className="mb-3">
                <div className="mb-1 block">
                  <Label htmlFor="new-personil" value="List Personils" />
                </div>
                <div className="flex flex-wrap gap-2">
                  {personelsToAdd.map((item, i) => (
                    <Badge
                      key={i}
                      className="cursor-pointer"
                      color="indigo"
                      size="sm"
                      icon={HiUserRemove}
                      onClick={(e) => {
                        remove(item);
                      }}
                    >
                      {item.name}
                    </Badge>
                  ))}
                </div>
              </div>
              <div className="mb-2">
                <div className="mb-1 block">
                  <Label htmlFor="activity" value="Activity" />
                </div>
                <Select
                  required={true}
                  value={iActivityId}
                  onChange={(e) => {
                    setIActivityId(+e.target.value);
                  }}
                >
                  <option value="" key={-1}>
                    -- Choose Activity --
                  </option>
                  {listMasterNonProgram.map((item, i) => (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="mb-2">
                <div className="mb-1 block">
                  <Label htmlFor="note" value="Note" />
                </div>
                <TextInput ref={iNoteRef} name={'i-note'} />
              </div>
              <div className="mb-2">
                <div className="mb-1 block">
                  <Label htmlFor="location" value="Location" />
                </div>
                <Select
                  required={true}
                  value={iLocation}
                  onChange={(e) => {
                    setILocation(+e.target.value);
                  }}
                >
                  <option value="" key={-1}>
                    -- Choose Location --
                  </option>
                  {listLocaltion.map((item, i) => (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="mb-2">
                <div className="mb-1 block">
                  <Label htmlFor="start-datetime" value="Start Date Time" />
                </div>
                {startDate ? (
                  <DatePicker
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    selected={iDatetimeStart}
                    onChange={(date) => setIDatetimeStart(date)}
                    timeClassName={(time) => {
                      return time.getHours() > 12 ? 'text-success' : 'text-error';
                    }}
                  />
                ) : (
                  <DatePicker
                    showTimeSelect
                    selected={iDatetimeStart}
                    onChange={(date) => setIDatetimeStart(date)}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeClassName={(time) => {
                      return time.getHours() > 12 ? 'text-success' : 'text-error';
                    }}
                  />
                )}
              </div>
              <div className="mb-4">
                <div className="mb-1 block">
                  <Label htmlFor="end-datetime" value="End Date Time" />
                </div>
                {endDate ? (
                  <DatePicker
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    selected={iDatetimeEnd}
                    onChange={(date) => setIDatetimeEnd(date)}
                    timeClassName={(time) => {
                      return time.getHours() > 12 ? 'text-success' : 'text-error';
                    }}
                  />
                ) : (
                  <DatePicker
                    showTimeSelect
                    selected={iDatetimeEnd}
                    onChange={(date) => setIDatetimeEnd(date)}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeClassName={(time) => {
                      return time.getHours() > 12 ? 'text-success' : 'text-error';
                    }}
                  />
                )}
              </div>
              <div>
                <Button type="submit" onClick={submit} className="w-full" isProcessing={loading}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const NewNonProgram = Object.assign(ModalNewNonProgram, {});

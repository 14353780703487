import React, { FC, useEffect, useRef, useState } from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';
import { Breadcrumb, Button, Label, Modal, Select, Timeline } from '../../lib';

import swal from 'sweetalert';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the CSS for styling

import {
  getMyListEvents,
  getMyListNonEvents,
  saveNonProgramWorklog,
  saveProgramWorklog,
} from '../../services/scheduler.service';
import {
  ActivityPage,
  EventCalendar,
  MyEvent,
  MyEventListRequest,
  MyEventListResponse,
  MyNonEvent,
  MyNonEventListRequest,
  MyNonEventListResponse,
  NonEvent,
  SaveNonProgramWorklogRequest,
  SaveNonProgramWorklogResponse,
  SaveProgramWorklogRequest,
  SaveProgramWorklogResponse,
  SaveWorklog,
} from '../../types';
import { formatDateTime, getDateOnly, getTimeOnly } from '../../utils/date.util';
import { log } from '../../utils/log.util';

let calendarStart = null;
let calendarEnd = null;
let currentMonth = -1;
let currentDateStart = null;
let currentDateEnd = null;

const MyCalendarPage: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<ActivityPage>(ActivityPage.SELECT_MONTH);
  const [backgroundCalendar, setBackgroundCalendar] = useState<EventCalendar[]>([]);
  const [eventsCalendar, setEventsCalendar] = useState<EventCalendar[]>([]);
  const [events, setEvents] = useState<MyEvent[]>([]);
  const [nonEvents, setNonEvents] = useState<MyNonEvent[]>([]);
  const [initialDate, setInitialDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const [selectedEvent, setSelectedEvent] = useState<MyEvent>(null);
  const [selectedNonEvent, setSelectedNonEvent] = useState<MyNonEvent>(null);
  const [selectMonth, setSelectMonth] = useState<string>('01');
  const [selectYear, setSelectYear] = useState<string>('2023');
  const [selectStatus, setSelectStatus] = useState<string>('7');
  const [modalAddPersonel, setModalAddPersonel] = useState<boolean>(false);
  const [modalAddPersonelNonProgram, setModalAddPersonelNonProgram] = useState<boolean>(false);
  const [modalConfirmRemovePersonel, setModalConfirmRemovePersonel] = useState<boolean>(false);
  const [modalConfirmRemovePersonelNonProgram, setModalConfirmRemovePersonelNonProgram] = useState<boolean>(false);
  const [modalPickDate, setModalPickDate] = useState<boolean>(false);
  const [modalNewNonProgram, setModalNewNonProgram] = useState<boolean>(false);
  const [modalFilter, setModalFilter] = useState<boolean>(false);
  const [modalNewNonProgramWDate, setModalNewNonProgramWDate] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
  const [requiredPositionTitle, setRequiredPositionTitle] = useState('');
  const [requiredPositionCode, setRequiredPositionCode] = useState('');
  const [contentWorklogProgram, setContentWorklogProgram] = useState('');
  const [contentWorklogNonProgram, setContentWorklogNonProgram] = useState('');

  const calendar = useRef(null);

  const [pangkatUser, setPangkatUser] = useState(() => {
    const saved = localStorage.getItem('user_login');
    const initialValue = JSON.parse(saved);
    return (initialValue && initialValue.rank_func_id) || '0';
  });

  const quillRefWorklogProgram = useRef(null);
  const quillRefWorklogNonProgram = useRef(null);

  useEffect(() => {
    if (quillRefWorklogProgram.current) {
      quillRefWorklogProgram.current.focus();
    }
  }, []);

  useEffect(() => {
    if (quillRefWorklogNonProgram.current) {
      quillRefWorklogNonProgram.current.focus();
    }
  }, []);

  const quillStyle = {
    height: '400px', // Set the desired height
  };

  const handleChangeWorklogProgram = (value) => {
    setContentWorklogProgram(value);
  };

  const handleChangeWorklogNonProgram = (value) => {
    setContentWorklogNonProgram(value);
  };

  const calendarDateClick = (e) => {
    log(e, 'calendarDateClick-date');
    setPage(ActivityPage.DETAIL_ACTIVITY);
    setSelectedDate(e.date);
    const d = moment(e.date).format('YYYY-MM-DD');
    myListEventsInDay(d);
    myListNonEventsInDay(d);
  };

  const calendarEventClick = (ev) => {
    log(ev.event?._instance?.range?.start, 'calendarEventClick-date');
    setPage(ActivityPage.DETAIL_ACTIVITY);
    setSelectedDate(ev.event?._instance?.range?.start);
    const d = moment(ev.event?._instance?.range?.start).format('YYYY-MM-DD');
    myListEventsInDay(d);
    myListNonEventsInDay(d);
  };

  const calendarGotoDate = (e) => {
    log({ selectMonth, selectYear });
    const calendarApi = calendar.current.getApi();
    calendarApi.gotoDate(`${selectYear}-${selectMonth}-01`);
    setModalPickDate(false);
  };

  const calendarFilter = (e) => {
    log('calendarFilter');
    setModalFilter(false);
    if (currentDateStart && currentDateEnd) {
      setEventsCalendar([]);
      myListEvents(currentDateStart, currentDateEnd);
      myListNonEvents(currentDateStart, currentDateEnd);
    }
  };

  const worklogProgramClick = (ev: MyEvent) => {
    setPage(ActivityPage.PERSONAL_ASSIGNED);
    setSelectedEvent(ev);
    setContentWorklogProgram(ev.worklog);
  };

  const worklogNonProgramClick = (ev: MyNonEvent) => {
    setPage(ActivityPage.PERSONAL_ASSIGNED_NON_PROGRAM);
    setSelectedNonEvent(ev);
    setContentWorklogNonProgram(ev.worklog);
  };

  const monthHandleChange = (e) => {
    setSelectMonth(e.target.value);
  };

  const statusHandleChange = (e) => {
    setSelectStatus(e.target.value);
  };

  const yearHandleChange = (e) => {
    setSelectYear(e.target.value);
  };

  const renderEventContent = (ev) => {
    return (
      <React.Fragment>
        <div
          className="overflow-hidden whitespace-nowrap px-1"
          data-tooltip-id={ev.event.title}
          data-tooltip-content={`${ev.event.extendedProps.category} - ${ev.event.title}`}
        >
          <b>{ev.timeText}</b> <span className="ml-1">{ev.event.title}</span>
        </div>
        <Tooltip id={ev.event.title} />
      </React.Fragment>
    );
  };

  const submitNonProgramWorklog = async (paramWorklog: SaveWorklog) => {
    log(paramWorklog, 'submitNonProgramWorklog');
    const params: SaveNonProgramWorklogRequest = {
      api_version: '1.0',
      action_type: 'save-non-program-worklog',
      token: cookies.access_token,
      parameters: {
        id: paramWorklog.id,
        worklog: paramWorklog.worklog,
      },
    };
    log(params, 'submitProgramWorklog-req');

    try {
      const updatedEvents = [];
      const response: SaveNonProgramWorklogResponse = await saveNonProgramWorklog(params);
      log(response, 'submitNonProgramWorklog-res');
      if (response.error_code === 0 && response.data !== null) {
        const newEvent = response.data.activity;
        setSelectedNonEvent(newEvent);
        setNonEvents((prevEvents) => {
          const updatedEvents = [...prevEvents];
          const index = updatedEvents.findIndex((event) => event.id === newEvent.id);
          if (index !== -1) {
            updatedEvents.splice(index, 1, newEvent);
          }
          return updatedEvents;
        });
        setEventsCalendar([]);
        const ec = updatedEvents.map((ev) => {
          let color;
          return {
            start: ev.datetime_start,
            end: ev.datetime_end,
            id: ev.activity_id,
            title: `${ev.activity_name}`,
            color: color,
            category: 'Program',
          };
        });
        setEventsCalendar((prevEventsCalendar) => prevEventsCalendar.concat(ec));
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'submitProgramWorklog-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const submitProgramWorklog = async (paramWorklog: SaveWorklog) => {
    log(paramWorklog, 'submitProgramWorklog');
    const params: SaveProgramWorklogRequest = {
      api_version: '1.0',
      action_type: 'save-program-worklog',
      token: cookies.access_token,
      parameters: {
        id: paramWorklog.id,
        worklog: paramWorklog.worklog,
      },
    };
    log(params, 'submitProgramWorklog-req');

    try {
      const updatedEvents = [];
      const response: SaveProgramWorklogResponse = await saveProgramWorklog(params);
      log(response, 'submitProgramWorklog-res');
      if (response.error_code === 0 && response.data !== null) {
        const newEvent = response.data.activity;
        setSelectedEvent(newEvent);
        setEvents((prevEvents) => {
          const updatedEvents = [...prevEvents];
          const index = updatedEvents.findIndex((event) => event.id === newEvent.id);
          if (index !== -1) {
            updatedEvents.splice(index, 1, newEvent);
          }
          return updatedEvents;
        });
        setEventsCalendar([]);
        const ec = updatedEvents.map((ev) => {
          let color;
          return {
            start: ev.datetime_start,
            end: ev.datetime_end,
            id: ev.activity_id,
            title: `${ev.activity_name}`,
            color: color,
            category: 'Program',
          };
        });
        setEventsCalendar((prevEventsCalendar) => prevEventsCalendar.concat(ec));
      } else {
        if (response.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', response.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'submitProgramWorklog-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const myListEventsInDay = async (date) => {
    let status = 7;
    if (selectStatus) {
      status = +selectStatus;
    }
    const params: MyEventListRequest = {
      api_version: '1.0',
      action_type: 'my-program-calendar',
      token: cookies.access_token,
      parameters: {
        datetime_start: `${date} 00:00`,
        datetime_end: `${date} 23:59`,
      },
    };
    log(params, 'myListEventsInDay-req');
    try {
      setLoading(true);
      const list: MyEventListResponse = await getMyListEvents(params);
      setLoading(false);
      log(list, 'myListEventsInDay-res');

      setEvents([]);
      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.activities?.length != 0) {
          setEvents(list.data.activities);
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'myListEventsInDay-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const myListNonEventsInDay = async (date) => {
    const params: MyNonEventListRequest = {
      api_version: '1.0',
      action_type: 'my-non-program-calendar',
      token: cookies.access_token,
      parameters: {
        datetime_start: `${date} 00:00`,
        datetime_end: `${date} 23:59`,
      },
    };
    log(params, 'mylistNonEventsInDay-req');
    setLoading(true);
    try {
      const list: MyNonEventListResponse = await getMyListNonEvents(params);
      setLoading(false);
      log(list, 'mylistNonEventsInDay-res');
      setNonEvents([]);
      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.activities) {
          if (list?.data?.activities?.length != 0) {
            setNonEvents(list.data.activities);
          }
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'mylistNonEventsInDay-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  const myListEvents = async (startDate, lastDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const lastDateObj = new Date(lastDate);

    while (currentDate <= lastDateObj) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const requests = dates.map(async (date) => {
      const status = selectStatus ? +selectStatus : 7;

      const params = {
        api_version: '1.0',
        action_type: 'my-program-calendar',
        token: cookies.access_token,
        parameters: {
          datetime_start: `${date} 00:00`,
          datetime_end: `${date} 23:59`,
        },
      };

      log(params, 'myListEvents-req');

      try {
        const list = await getMyListEvents(params);
        log(list, 'myListEvents-res');

        if (list.error_code === 0 && list.data !== null && list.data.activities !== null) {
          if (list?.data?.activities && list.data.activities.length !== 0) {
            setEvents((prevEvents) => (prevEvents ? prevEvents.concat(list.data.activities) : list.data.activities));

            const ec = list.data.activities.map((ev) => {
              let color;

              try {
                const parsedWorklog = JSON.parse(ev.worklog);

                if (parsedWorklog && parsedWorklog.ops.length === 1) {
                  color = 'red'; // Empty editor
                  if (parsedWorklog.ops[0].insert.length > 1) {
                    color = 'green';
                  }
                } else {
                  color = 'green'; // Non-empty editor
                }
              } catch (error) {
                color = 'red'; // Handle error (worklog is empty string)
              }

              return {
                start: ev.datetime_start,
                end: ev.datetime_end,
                id: ev.activity_id,
                title: `${ev.activity_name}`,
                color: color,
                category: 'Program',
              };
            });
            setEventsCalendar((prevEventsCalendar) => prevEventsCalendar.concat(ec));
          }
        } else {
          if (list.error_code === 4006) {
            removeCookie('access_token', { path: '/' });
            window.location.href = '/#/login';
            window.location.reload();
          } else {
            //swal('Error',list.message, 'error')
          }
        }
      } catch (e) {
        log(e, 'myListEvents-err');
        if (e.response && e.response.data.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        }
      }
    });

    setLoading(true);
    await Promise.all(requests);
  };

  const myListNonEvents = async (startDate, lastDate) => {
    const params: MyNonEventListRequest = {
      api_version: '1.0',
      action_type: 'my-non-program-calendar',
      token: cookies.access_token,
      parameters: {
        datetime_start: `${startDate} 00:00`,
        datetime_end: `${lastDate} 23:59`,
      },
    };
    log(params, 'myListNonEvents-req');
    setLoading(true);
    try {
      const list: MyNonEventListResponse = await getMyListNonEvents(params);
      setLoading(false);
      log(list, 'myListNonEvents-res');

      if (list.error_code === 0 && list.data !== null) {
        if (list?.data?.activities && list?.data?.activities.length != 0) {
          setNonEvents(list.data.activities);
          const ec: EventCalendar[] = list.data.activities.map((ev: NonEvent) => {
            let color;

            try {
              const parsedWorklog = JSON.parse(ev.worklog);

              if (parsedWorklog && parsedWorklog.ops.length === 1) {
                color = 'red'; // Empty editor
                if (parsedWorklog.ops[0].insert.length > 1) {
                  color = 'green';
                }
              } else {
                color = 'green'; // Non-empty editor
              }
            } catch (error) {
              color = 'red'; // Handle error (worklog is empty string)
            }

            return {
              start: ev.datetime_start,
              end: ev.datetime_end,
              id: ev.activity_id,
              title: ev.activity_name,
              color: color,
              category: 'Non-Program',
            };
          });
          setEventsCalendar((current) => [...current, ...ec]);
        }
      } else {
        if (list.error_code === 4006) {
          removeCookie('access_token', { path: '/' });
          window.location.href = '/#/login';
          window.location.reload();
        } else {
          swal('Error', list.message, 'error');
        }
      }
    } catch (e) {
      log(e, 'myListNonEvents-err');
      if (e.error_code === 4006) {
        removeCookie('access_token', { path: '/' });
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    log({ y, m: m + 1 });
  }, []);

  const handleMonthChange = async (payload) => {
    if (calendarStart != payload.startStr) {
      log({ calendarStart, calendarEnd, payload }, 'handleMonthChange');
      calendarStart = payload.startStr;
      calendarEnd = payload.endStr;
      if (payload.view.type === 'dayGridMonth') {
        const m = new Date((payload.start.getTime() + payload.end.getTime()) / 2);
        log(m, 'handleMonthChange-mid');
        const year = m.getFullYear(),
          month = m.getMonth() + 1;
        if (currentMonth != month) {
          currentMonth = month;
          setInitialDate(m);
          currentDateStart = moment(payload.start).format('YYYY-MM-DD');
          currentDateEnd = moment(payload.end).format('YYYY-MM-DD');
          setEventsCalendar([]);
          myListEvents(currentDateStart, currentDateEnd);
          myListNonEvents(currentDateStart, currentDateEnd);
        }
      }
    }
  };

  const SelectMonth = () => {
    return (
      <div className="mx-5 my-3">
        <FullCalendar
          ref={calendar}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'filter today,goToDate dayGridMonth,prev,next',
          }}
          customButtons={{
            goToDate: {
              text: 'Pick Date',
              click: (e) => {
                setModalPickDate(true);
              },
            },
            filter: {
              text: 'Filter',
              click: (e) => {
                setModalFilter(true);
              },
            },
          }}
          initialView="dayGridMonth"
          initialDate={initialDate}
          events={eventsCalendar}
          editable={true}
          eventContent={renderEventContent}
          dateClick={calendarDateClick}
          eventClick={calendarEventClick}
          height={900}
          contentHeight={780}
          aspectRatio={3}
          nowIndicator={true}
          dayMaxEvents={2}
          navLinks={true}
          eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
          eventDisplay="block"
          datesSet={handleMonthChange}
          views={{
            dayGridMonth: { buttonText: 'Month' },
            timeGridWeek: { buttonText: 'Week', visible: false }, // Hide week view
            timeGridDay: { buttonText: 'Day', visible: false }, // Hide day view
          }}
          buttonText={{
            today: 'Today',
          }}
        />
      </div>
    );
  };

  const DetailActivity = () => {
    return (
      <div className="mx-5 my-3">
        <h2 className="mb-2 mb-3 text-3xl text-gray-900 dark:text-white lg:text-3xl lg:leading-snug">
          <span
            className="m-auto inline-block cursor-pointer pr-2 align-middle"
            onClick={(e) => {
              setPage(ActivityPage.SELECT_MONTH);
            }}
          >
            <HiArrowNarrowLeft className="h-6 w-6" />
          </span>
          {selectedDate && moment(selectedDate).format('dddd, DD MMMM YYYY')}
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Programs</h5>
            <Timeline className="ml-2 mt-3">
              {events &&
                events.map((item, i) => (
                  <Timeline.Item className="mb-5">
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>
                        {`${item.datetime_start.split(' ')[1]} - ${item.datetime_end.split(' ')[1]}`} WIB &#x2022;{' '}
                      </Timeline.Time>
                      <Timeline.Title>{`${item.activity_name}`}</Timeline.Title>
                      <Timeline.Body></Timeline.Body>
                      <div className="flex flex-wrap gap-2">
                        <Button outline={true} color="gray" onClick={(e) => worklogProgramClick(item)}>
                          Worklog
                          <HiArrowNarrowRight className="ml-2 h-3 w-3" />
                        </Button>
                      </div>
                    </Timeline.Content>
                  </Timeline.Item>
                ))}
            </Timeline>
          </div>
          <div>
            <div className="inline-flex">
              <h5 className="mb-auto mr-3 mt-auto text-xl font-bold leading-none text-gray-900 dark:text-white">
                Non-Programs
              </h5>
            </div>
            <Timeline className="ml-2 mt-3">
              {nonEvents &&
                nonEvents.map((item, i) => (
                  <Timeline.Item className="mb-5">
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>
                        {getDateOnly(item.datetime_start) == getDateOnly(item.datetime_end)
                          ? `${getTimeOnly(item.datetime_start)} til ${getTimeOnly(item.datetime_end)}`
                          : ''}
                        {getDateOnly(item.datetime_start) != getDateOnly(item.datetime_end)
                          ? `${getTimeOnly(item.datetime_start)} 
                          til ${formatDateTime(item.datetime_end, 'MMMM d, h:mm aa')}`
                          : ''}
                      </Timeline.Time>
                      <Timeline.Title>{item.activity_name}</Timeline.Title>
                      <Timeline.Body></Timeline.Body>
                      <div className="flex flex-wrap gap-2">
                        <Button outline={true} color="gray" onClick={(e) => worklogNonProgramClick(item)}>
                          Worklog
                          <HiArrowNarrowRight className="ml-2 h-3 w-3" />
                        </Button>
                      </div>
                    </Timeline.Content>
                  </Timeline.Item>
                ))}
            </Timeline>
          </div>
        </div>
      </div>
    );
  };

  const worklogProgramInput = () => {
    const handleSaveWorklog = () => {
      const delta = quillRefWorklogProgram.current?.getEditor().getContents();
      const worklog = JSON.stringify(delta);

      const paramWorklog = {
        id: selectedEvent ? selectedEvent.id : null,
        worklog: worklog,
      };
      submitProgramWorklog(paramWorklog);
    };
    setContentWorklogProgram(selectedEvent.worklog);
    return (
      <div className="mx-5 my-3">
        <div className="mb-3 flex">
          <div>
            <h5 className="ml-8 text-xl leading-none text-gray-900 dark:text-white">Worklog</h5>
            <div className="flex">
              <h2 className="mb-2 text-3xl text-gray-900 dark:text-white lg:text-3xl lg:leading-snug">
                <span
                  className="m-auto inline-block cursor-pointer pr-2 align-middle"
                  onClick={(e) => {
                    setPage(ActivityPage.DETAIL_ACTIVITY);
                  }}
                >
                  <HiArrowNarrowLeft className="h-6 w-6" />
                </span>
                {selectedEvent && `${selectedEvent.activity_name}`}
              </h2>
            </div>
          </div>
        </div>
        <div>
          {contentWorklogProgram !== '' ? (
            <ReactQuill ref={quillRefWorklogProgram} value={JSON.parse(contentWorklogProgram)} style={quillStyle} />
          ) : (
            <ReactQuill ref={quillRefWorklogProgram} value="" style={quillStyle} />
          )}

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button outline={true} color="gray" style={{ marginTop: '70px' }} onClick={handleSaveWorklog}>
              Save Worklog
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const worklogNonProgramInput = () => {
    const handleSaveWorklog = () => {
      const delta = quillRefWorklogNonProgram.current?.getEditor().getContents();
      const worklog = JSON.stringify(delta);

      const paramWorklog = {
        id: selectedNonEvent ? selectedNonEvent.id : null,
        worklog: worklog,
      };
      submitNonProgramWorklog(paramWorklog);
    };

    setContentWorklogNonProgram(selectedNonEvent.worklog);

    return (
      <div className="mx-5 my-3">
        <div className="mb-3 flex">
          <div>
            <h5 className="ml-8 text-xl leading-none text-gray-900 dark:text-white">Worklog</h5>
            <h2 className="mb-2 text-3xl text-gray-900 dark:text-white lg:text-3xl lg:leading-snug">
              <span
                className="m-auto inline-block cursor-pointer pr-2 align-middle"
                onClick={(e) => {
                  setPage(ActivityPage.DETAIL_ACTIVITY);
                }}
              >
                <HiArrowNarrowLeft className="h-6 w-6" />
              </span>
              {selectedNonEvent && selectedNonEvent.activity_name}
            </h2>
          </div>
        </div>
        <div>
          {contentWorklogNonProgram !== '' ? (
            <ReactQuill
              ref={quillRefWorklogNonProgram}
              value={JSON.parse(contentWorklogNonProgram)}
              style={quillStyle}
            />
          ) : (
            <ReactQuill ref={quillRefWorklogNonProgram} value="" style={quillStyle} />
          )}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button outline={true} color="gray" style={{ marginTop: '70px' }} onClick={handleSaveWorklog}>
              Save Worklog
            </Button>
          </div>
        </div>
      </div>
    );
  };

  let CurrentPage = SelectMonth;
  if (page == ActivityPage.SELECT_MONTH) {
    CurrentPage = SelectMonth;
  } else if (page == ActivityPage.DETAIL_ACTIVITY) {
    CurrentPage = DetailActivity;
  } else if (page == ActivityPage.PERSONAL_ASSIGNED) {
    CurrentPage = worklogProgramInput;
  } else if (page == ActivityPage.PERSONAL_ASSIGNED_NON_PROGRAM) {
    CurrentPage = worklogNonProgramInput;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-0 dark:text-white lg:px-4 lg:py-0">
      <h2 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white lg:text-4xl lg:font-extrabold lg:leading-snug">
        My Schedule
      </h2>
      <div className="rounded-xl border border-gray-100 bg-white shadow transition hover:border-white hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:shadow-gray-800 dark:hover:border-gray-600">
        <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-900">
          <Breadcrumb.Item>
            <span className={`${page == ActivityPage.SELECT_MONTH ? 'text-teal-500' : ''}`}>Calendar</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className={`${page == ActivityPage.DETAIL_ACTIVITY ? 'text-teal-500' : ''}`}>Activities</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              className={`${
                page == ActivityPage.PERSONAL_ASSIGNED || page == ActivityPage.PERSONAL_ASSIGNED_NON_PROGRAM
                  ? 'text-teal-500'
                  : ''
              }`}
            >
              Worklog
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="flex flex-col gap-8 dark:text-white" style={{ height: '950px' }}>
          <CurrentPage />
        </div>
      </div>
      <Modal
        show={modalPickDate}
        size="xl"
        popup={true}
        onClose={() => {
          setModalPickDate(false);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex text-center">
            <Select
              id="month"
              required={true}
              className="mr-2 flex-auto"
              value={selectMonth}
              onChange={monthHandleChange}
            >
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Select>
            <Select id="year" required={true} className="mr-2 flex-auto" value={selectYear} onChange={yearHandleChange}>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </Select>
            <Button type="submit" onClick={calendarGotoDate}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalFilter}
        size="xl"
        popup={true}
        onClose={() => {
          setModalFilter(false);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex">
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="endDate" value="Filter Status Program" />
              </div>
              <Select
                id="status"
                required={true}
                className="mb-3 ml-auto"
                value={selectStatus}
                onChange={statusHandleChange}
              >
                <option value="7">On Booking</option>
                <option value="9">Cancel</option>
                <option value="12">On Booking - Zero Assignment</option>
                <option value="13">On Booking - Incomplete Assignment</option>
                <option value="0">All</option>
              </Select>
              <Button type="submit" onClick={calendarFilter}>
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyCalendarPage;

export const randomColor = (brightness) => {
  function randomChannel(brightness) {
    const r = 255 - brightness;
    const n = 0 | (Math.random() * r + brightness);
    const s = n.toString(16);
    return s.length == 1 ? '0' + s : s;
  }
  return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
};

export const formatCurrency = (amount: any | string, unit?: string) => {
  let value = '';
  value = new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
  if (unit) {
    value = `${unit} ${value}`;
  }
  return value;
};

export const formatOldCurrency = (amount: any | string, unit?: string) => {
  let value = '';
  const parseToFloat = parseFloat(amount);
  const parts = parseToFloat.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  value = parts.join('.');
  if (unit) {
    value = `${unit} ${value}`;
  }
  return value;
};

export const formatSupportedCurrencies = (word: string) => {
  return word.split(',').join(', ');
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const capitalizeLetter = (word: string) => {
  let titleLabel = '';
  const splitWords = word.split(/(?=[A-Z])/);
  const removeSpace = splitWords.join(' ');
  titleLabel = removeSpace.replace(/(^\w|\s\w)/g, (firstAlphabet) => firstAlphabet.toUpperCase());
  return titleLabel;
};

export const formatFloat = (words: any) => {
  return parseFloat(words);
};

export const getFileName = (url: string) => {
  if (url) {
    const m = url.toString().match(/[^/\\&?#]+\.\w{3,4}(?=([?&#].*$|$))/);
    if (m && m.length > 1) {
      return m[0];
    }
  }
  return '';
};

export const formatString = (words: string, long: number, type?: 'normal' | 'reverse') => {
  if (!type) type = 'normal';
  if (words.length > long) {
    if (type === 'reverse') return `...${words.slice(words.length - long, words.length)}`;
    if (type === 'normal') return `${words.slice(words.length - long, words.length)}...`;
  } else {
    return words;
  }
};

export const humanize = (str: string) => {
  const frags = str.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};
